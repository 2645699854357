import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export default function SecretLogin() {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(()=>{
    if(searchParams.get("token")){
      window.localStorage.setItem("USER_TOKEN", searchParams.get("token"));
      if(searchParams.get("is_new_user")){
        window.location.href = "/edit-profile";
      } else if(searchParams.get("course_id")){
        window.location.href = "/tutorials";
      }
    } else {
      window.location.href = "/";
    }
  },[]);

  return (
    <div>
      SecretLogin
    </div>
  )
}