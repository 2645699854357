import React, { useEffect, useState } from 'react'
import { axiosClientPy } from '../utils';
import { getDateFormat } from '../utils';
import SearchComponent from "./SearchComponent";
import NotificationPopup from './NotificationPopup';

export default function TopRightProfile() {
  const [data, setData] = useState(null);

  const getUserData = async () => {
    try {
      const headers = {
        Authorization: "Token " + window.localStorage.getItem("USER_TOKEN")
      }
      const res = await axiosClientPy.get("/account/get-profile", { headers });
      setData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="with-user-row">
      <div className="title-top-row">
        <div className="title-top-row__left">
          <h1 className="lh-1 fw600">Create A New Project</h1>
          <p>
            <img
              src="/assets/images/icons/last-visited.svg"
              alt="Calendar Icon denoting when was last updated"
            />
            <span>Last updated {getDateFormat(new Date())[2]} - {getDateFormat(new Date())[3]}</span>
          </p>
        </div>
        <SearchComponent />
      </div>
      <div className="sidebar-right-inner__top">
        <img
          className="rounded-circle user"
          src={data?.profile_image ? data?.profile_image : "/assets/images/new/team-creative.svg"}
          alt="user display picture"
          style={{ width: "60px", height: "60px", objectFit: 'contain', borderRadius: "50%", background: '#e24c4d', border: "2px solid #24262b" }}
        />
        <div className="title-row name-info">
          <h2 className="fw700">Welcome Back, {data?.full_name}!</h2>
          <p className="mb-0">
            <img
              src="/assets/images/icons/last-visited.svg"
              alt="Calendar Icon denoting when was last updated"
            />
            <span>{getDateFormat(new Date())[2]} - {getDateFormat(new Date())[3]}</span>
          </p>
        </div>
        <NotificationPopup />
        <div className="dropdown">
          <a
            className="btn fw500 border-0 p-0 dropdown-toggle"
            href="#"
            role="button"
            id="languageLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            EN
          </a>
          <ul className="dropdown-menu" aria-labelledby="languageLink">
            <li><a className="fw500 dropdown-item" href="#">EN</a></li>
            <li><a className="fw500 dropdown-item" href="#">FR</a></li>
            <li>
              <a className="fw500 dropdown-item" href="#">DU</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
