import React, { useEffect } from 'react'
import LeftSidebar from '../components/LeftSidebar'
import TopRightProfile from '../components/TopRightProfile'
import ProjectListWidget from '../components/ProjectListWidget';
import useFetch from '../utils/hooks/useFetch';
import Loading from '../components/Loading';
import { getDateFormat } from '../utils';
import MultiLineChart from '../components/MultiLineChart';

const mScript = `// serviceStatsLineChart
if (document.getElementById("serviceStatsLineChart")) {
  var ctx = document.getElementById("serviceStatsLineChart").getContext("2d");
  const mode = "index";

  var gradient1 = ctx.createLinearGradient(0, 0, 0, 280);
  gradient1.addColorStop(0, "rgba(241, 233, 243, 0.50)");
  gradient1.addColorStop(1, "rgba(241, 233, 243, 0)");

  var gradient2 = ctx.createLinearGradient(0, 0, 0, 280);
  gradient2.addColorStop(0, "rgba(40, 40, 40, 0.30)");
  gradient2.addColorStop(1, "rgba(40, 40, 40, 0)");

  var myChart = new Chart(ctx, {
    type: "line",
    data: {
      labels: [
        "2000",
        "2001",
        "2002",
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
      ],
      datasets: [
        {
          label: "",
          fill: "origin",
          borderColor: "#282828",
          backgroundColor: gradient2,
          borderWidth: 5,
          data: [40, 100, 50, 85, 70, 120, 150, 130, 160, 200],
          pointBorderColor: "rgba(0, 0, 0, 0)",
          pointBackgroundColor: "rgba(0, 0, 0, 0)",
          pointHoverBackgroundColor: "#282828",
          pointHoverBorderColor: "#ffffff",
          pointHoverBorderWidth: 8,
          pointHoverRadius: 8,
          order: 1,
          fill: "origin",
        },
        {
          label: "",
          fill: "origin",
          borderColor: "#EFDFEF",
          backgroundColor: gradient1,
          borderWidth: 5,
          data: [0, 120, 80, 125, 150, 100, 180, 100, 220, 100],
          pointBorderColor: "rgba(0, 0, 0, 0)",
          pointBackgroundColor: "rgba(0, 0, 0, 0)",
          pointHoverBackgroundColor: "#EFDFEF",
          pointHoverBorderColor: "#ffffff",
          pointHoverBorderWidth: 8,
          pointHoverRadius: 8,
          order: 2,
        }, 
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      tooltip: {
        padding: 8,
        backgroundColor: "rgba(0, 0, 0, 0.48)",
        titleFont: {
          weight: "bold",
        },
      },
      hover: {
        mode: mode,
        intersect: false,
      },
      scales: {
        x: {
          beginAtZero: true,
          grid: {
            color: "#f7f8fe",
          },
          ticks: {
            color: "#8F92A1",
          },
          border: {
            color: "#f7f8fe",
          },
        },

        y: {
          beginAtZero: true,
          labels: [100, 200, 300, 400, 500],
          grid: {
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            minRotation: 90,
            padding: 10,
            color: "#8F92A1",
          },
          border: {
            color: "#f7f8fe",
          },
        },
      },
      elements: {
        line: {
          tension: 0.45,
        },
      },
      layout: {
        padding: {
          top: 15,
          bottom: 15,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  });
}

// Toggle btw services' & projects' GRIDS on dropdown-item selection //
$(".dd-item-custom").on("click", function () {
  // Remove the "dd-active" class from all dropdown items
  $(".dd-item-custom").removeClass("dd-active");
  $(".dd-item-line").removeClass("dd-active");

  // Get the selected class and add the "dd-active" class to the clicked item
  var selectedClass = $(this).data("class");
  
  $(this).addClass("dd-active");

  // Toggle the selected class in div.toggle-grid
  $("#toggle-grid")
    .removeClass()
    .addClass("toggle-grid")
    .addClass(selectedClass);

  $("#toggle-line-chart")
    .removeClass()
    .addClass("toggle-line-chart")
    .addClass(selectedClass);

  if (selectedClass == "product-grid") {
    $("#js--deploy").html("Add new product");
    $("#dropdownMenuButton").html("Products");
    $(".dd-item-line").first().addClass("dd-active")
    $("#ddLineMenuBtn").html("Products");
  } else {
    $("#js--deploy").html("Create new services");
    $("#dropdownMenuButton").html("Services");
    $(".dd-item-line").last().addClass("dd-active")
    $("#ddLineMenuBtn").html("Services");
  }
});
// =================================================================== //

// Toggle btw services' & projects' LINE-CHARTS on dropdown-item selection //
$(".dd-item-line").on("click", function () {
  // Remove the "dd-active" class from all dropdown items
  $(".dd-item-line").removeClass("dd-active");
  $(".dd-item-custom").removeClass("dd-active");

  // Get the selected class and add the "dd-active" class to the clicked item
  var selectedClass = $(this).data("class");

  $(this).addClass("dd-active");
  
  // Toggle the selected class in div.toggle-line-chart
  $("#toggle-line-chart")
    .removeClass()
    .addClass("toggle-line-chart")
    .addClass(selectedClass);
  
  $("#toggle-grid")
    .removeClass()
    .addClass("toggle-grid")
    .addClass(selectedClass);

    if (selectedClass == "product-grid") {
      $("#js--deploy").html("Add new product");
      $("#dropdownMenuButton").html("Products");
      $("#ddLineMenuBtn").html("Products");
      $(".dd-item-custom").first().addClass("dd-active")
    } else {
      $("#js--deploy").html("Create new services");
      $("#dropdownMenuButton").html("Services");
      $("#ddLineMenuBtn").html("Services");
      $(".dd-item-custom").last().addClass("dd-active")
    }
});
// =================================================================== //
`;

export default function Reports() {
  const { data, loading } = useFetch("/project/project-creation")
  const transactions = useFetch("/main/transaction-history")

  console.log(transactions);

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = mScript;
    script.id = "projectStatsLineChartScript";
    document.body.append(script);

    return () => {
      document.getElementById("projectStatsLineChartScript").remove();
    }
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <div className="mob-menu-btn"><span /><span /><span /></div>
      <main className="page-wrapper">
        <LeftSidebar active="report" />

        <section className="pe-0 main-content">
          <div className="pt-0 main-content-inner">
            <TopRightProfile />
            {/*----- Completion stats row -----*/}
            <div className="primary-box completion-row">
              <div className="completion-row__left">
                <a href="#" className="redirect-back">
                  <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
                </a>
                <div >
                  <h1 className="fw600 text-white">Reports</h1>
                  <div className="mb-0">
                    <span className="fw700">Last Updated: </span>
                    <span className="ms-1"> 01-June-2022 - 03hr:55min (EST)</span>
                  </div>
                </div>
              </div>
              <div className="completion-row__right">
                <a href="#" className="btn btn--deploy">
                  <span className="fw400">Download PDF</span>
                </a>
              </div>
            </div>
            {/* ============================== */}
            <div className="#">
              {/*------ Multi line chart widget ------*/}
              <div className="mt-3 row">
                <div className="col-8">
                  <MultiLineChart data={data} />
                </div>
                <div className="col-4">
                  <div className="sale-activities reports_activities">
                    {/* Sale widget */}
                    {/* Activities widget */}
                    <div className="primary-box sale-activities__right reports-activities-right">
                      <div className="top-row">
                        <div className="title-row">
                          <h2 className="lh1 mb-0 lh1-5 fw500">Recent Activities</h2>
                        </div>
                        <div className="dropdown">
                          <button className="btn border-0 dropdown-toggle btn-dd btn-dd--red" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            12hrs
                          </button>
                          <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">1hr</a></li>
                            <li><a className="dropdown-item" href="#">12hrs</a></li>
                            <li>
                              <a className="dropdown-item" href="#">24hrs</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <ul className="list-unstyled mb-0 activities">
                        {
                          data?.map(ele => (
                            <li>
                              <div className="activity">
                                <div className="activity__top">
                                  <img src="assets/images/icons/bar-chart-icon.svg" alt="" />
                                  <h3 className="fw500 m-0">New Project Added</h3>
                                </div>
                                <p className="mb-0">
                                  {ele.name} | Project Type: {ele.project_type} | Status: {ele.project_status === "in_progress" ? "In progress" : (ele.project_status === "verified" ? "Verified" : "Deployed")}.
                                </p>
                                <div className="time">Date {getDateFormat(ele.created_at)[0]} - {getDateFormat(ele.created_at)[0]}</div>
                              </div>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/*-------- Project List widget --------*/}
                <div className="col-8">
                  <ProjectListWidget data={data} />
                  {/* =================================== */}
                </div>
                <div className="col-4">
                  <div className="sale-activities reports_activities">
                    {/* Activities widget */}
                    <div className="w-100 mw-100 sale-activities__right reports-transaction-right">
                      <div className="top-row">
                        <div className="title-row">
                          <h2 className="lh1 mb-0 lh1-5 fw500">Transaction History</h2>
                        </div>
                        <div className="dropdown">
                          <button onClick={() => window.location.href = "/transaction-history"} className="btn border-0 btn-dd btn-dd--primary" type="button">
                            View All
                          </button>
                          {/* <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">1hr</a></li>
                            <li><a className="dropdown-item" href="#">12hrs</a></li>
                            <li>
                              <a className="dropdown-item" href="#">24hrs</a>
                            </li>
                          </ul> */}
                        </div>
                      </div>
                      <ul className="list-unstyled mb-0 activities">
                        {
                          transactions?.data?.map(ele => {
                            return (
                              <li>
                                <div className="activity">
                                  <div className="activity__top">
                                    <div className="img-cover">
                                      <img src="../assets/images/icons/incoming-trans.svg" alt="" />
                                    </div>
                                    {
                                      ele.is_captured ? <div class="status status--transferred">
                                        Transferred
                                      </div>
                                        : <div class="status status--inprogress">In progress</div>
                                    }

                                  </div>
                                  <p className="fz12">
                                    Your amount has been Transferred Successfully. - <strong className="fw600">${ele.amount}</strong>
                                  </p>
                                  <div className="time">
                                    Date {getDateFormat(ele.created_at)[0]} - {getDateFormat(ele.created_at)[1]}
                                  </div>
                                </div>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
