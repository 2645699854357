import React, { useEffect, useState } from 'react'
import TopRightProfile from '../components/TopRightProfile';
import LeftSidebar from '../components/LeftSidebar';
import { APP_DATA, axiosClientPy } from '../utils';
import { create as ipfsHttpClient } from "ipfs-http-client";
import { Buffer } from 'buffer';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';

export default function Certificates() {
  const projectId = process.env.REACT_APP_INFURA_IPFS_PROJECT_ID;
  const projectSecret = process.env.REACT_APP_INFURA_IPFS_PROJECT_SECRET;
  const projectIdAndSecret = `${projectId}:${projectSecret}`;
  const ipfsURL = "https://braands.infura-ipfs.io/ipfs/";
  const [data, setData] = useState([]);
  const headers = {
    Authorization: "Token " + window.localStorage.getItem("USER_TOKEN")
  }

  const client = ipfsHttpClient({
    host: "ipfs.infura.io",
    port: 5001,
    protocol: "https",
    headers: {
      authorization: `Basic ${Buffer.from(projectIdAndSecret).toString(
        "base64"
      )}`,
    },
  });

  const uploadFile = async (source) => {
    try {
      const added = await client.add(source, {
        progress: (prog) => {

        },
      });
      const url = `${ipfsURL}${added.path}`;
      return url;
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  const fetchMyNFT = async () => {
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const smart_contract = new ethers.Contract(APP_DATA.nft, APP_DATA.nftAbi, signer);
    const resp = await smart_contract.fetchmyNft();
    console.log(resp);
  }

  useEffect(() => {
    fetchMyNFT();
    axiosClientPy.get("/courses/my-certificates", { headers }).than(resp => {
      setData(resp.data.data);
    }).catch(err => console.log(err))
  }, [])

  const convertIntoNFT = async (info) => {
    const data = JSON.stringify({
      name: info?.course_name,
      image: info?.certificate
    });
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    try {
      const added = await client.add(data);
      const url = `${ipfsURL}${added.path}`;

      // const url = "https://braands.infura-ipfs.io/ipfs/QmeLYhf8NJrfZqE9smiw52oCAEPpmT6zF9arbhw7WW3Grj";
      const smart_contract = new ethers.Contract(APP_DATA.nft, APP_DATA.nftAbi, signer);
      const resp = await smart_contract.safeMint(signer.address, url, { value: 0 });
      console.log(resp);
    } catch (error) {
      toast.error(error?.reason);
      console.log("Error uploading file: ", error?.reason);
    }
  }

  return (
    <>
      {/* <Loading loading={loading} /> */}
      <div className="mob-menu-btn"><span /><span /><span /></div>
      <section className='page-wrapper'>
        <LeftSidebar active={"certificates"} />

        <section className="pe-0 main-content">
          <div className="pt-0 main-content-inner">
            <TopRightProfile />
            {/*----- Completion stats row -----*/}
            <div style={{ borderRadius: "25px" }} className="d-none d-lg-flex primary-box completion-row completion-row--back">
              <div className="completion-row__left">
                <a href="/dashboard" className="redirect-back">
                  <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
                </a>
                <div>
                  <h1 className="fw600 text-white">Certificates</h1>
                  <p className="m-0 grayText">
                    Watch &amp; learn from categorzed courses
                  </p>
                  <div className="mb-0">
                    <span className="fw700">Last Updated: </span>
                    <span className="ms-1"> 01-June-2022 - 03hr:55min (EST)</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='certificates-outer py-4'>
              <div className='row'>
                {
                  data?.filter(ele => ele.certificate).map(ele => (
                    <div className='col-md-4'>
                      <div className='certificates-inner'>
                        <div className='top-bar'>
                          <h2 className='fw700 text-white text-center mb-0'>{ele?.course_name}</h2>
                        </div>
                        <div className='certificates-body'>
                          <div>
                            <h5 className='mb-1 fw700'>Result</h5>
                            <p className='mb-1'>Total Questions : <b>{ele.total_questions_count}</b></p>
                            <p>Correct Questions : <b>{ele.correct_answers_count}</b></p>
                          </div>
                          <hr className='mt-0' />
                          <div className='w-100'>
                          </div>
                          <p className='mb-1'>Congratulations, You have earned a Certificate for this course.</p>
                          <a href={ele.certificate} target='_blank' className='btn mt-2 btn-dd--primary'>
                            Download Certificate
                          </a>
                          <button onClick={() => convertIntoNFT(ele)} className='btn ms-2 mt-2 btn-dd--primary'>
                            Convert into NFT
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>

          </div>
        </section>

      </section >
    </>
  )
}