import React, { useState } from 'react'
import TopRightProfile from '../../TopRightProfile'
import { axiosClientPy } from '../../../utils';
import { toast } from 'react-toastify';

export default function CreateProjectDaoStep1({ projectData, setStep }) {
  const [input, setInput] = useState({
    name: '',
    governanceAddress: ''
  })

  const onSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      Authorization: "Token " + window.localStorage.getItem("USER_TOKEN")
    }
    try {
      const mData = new FormData();
      mData.append("project_id", projectData?.uuid);
      mData.append("name", input.name);
      mData.append("governance_address", input.governanceAddress);
      mData.append("step_count", 4);
      const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
      setStep(2);
    } catch (error) {
      toast.error("Internal Server Error!!!")
    }
  }

  return (
    <>
      <section className="pe-0 main-content">
        <div className="pt-0 main-content-inner box-steps--illustration">
          <TopRightProfile />
          {/*----- Completion stats row -----*/}
          <div className="primary-box completion-row completion-row--back">
            <div className="completion-row__left">
              <a href="/new-project" className="redirect-back">
                <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
              </a>
              <div className>
                <h1 className="fw600 text-white">DAO</h1>
                <p className="m-0 grayText">
                  Merging technology to remove third parties and centralized
                  institutions from financial transactions.
                </p>
              </div>
            </div>
            <div className="completion-row__right">
              <img className="logo-crypto" src="/assets/images/defi-logo.svg" alt="" />
            </div>
          </div>
          {/* ============================== */}
          <div className="box-steps">
            <h2 className="text-center mb-0 fst-italic fw700">
              Assign your Token a unique Name!
            </h2>
            <p className="text-center grayText--lighter">
              Are you ready to make your token stand out in the crowd? Let your creativity flourish and give your token a name that resonates, intrigues, and leaves a lasting impact in the world of digital assets.
            </p>
            <div className="w-100 form-box form-box--purple">
              <form onSubmit={onSubmit} className="form">
                <div className="top">
                  <div className="form-group">
                    <div className="form-group__inner">
                      <label htmlFor className="label">
                        <div className="primary-box label__icon">
                          <img src="../assets/images/icons/nft-name.svg" alt="User icon" />
                        </div>
                      </label>
                      <input type="text" value={input.name} onChange={(e) => setInput({ ...input, name: e.target.value })} className="rounded-0 border-0 bg-transparent p-0 form-control italic-placeholder" placeholder="Write name the your Token" />
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <div className="form-group__inner">
                      <label htmlFor className="label">
                        <div className="primary-box label__icon">
                          <img src="../assets/images/icons/nft-name.svg" alt="User icon" />
                        </div>
                      </label>
                      <input type="text" className="rounded-0 border-0 bg-transparent p-0 form-control italic-placeholder" placeholder="Owner Address" />
                    </div>
                  </div> */}
                  <div className="form-group">
                    <div className="form-group__inner">
                      <label htmlFor className="label">
                        <div className="primary-box label__icon">
                          <img src="../assets/images/icons/nft-name.svg" alt="User icon" />
                        </div>
                      </label>
                      <input type="text" value={input.governanceAddress} onChange={(e) => setInput({ ...input, governanceAddress: e.target.value })} className="rounded-0 border-0 bg-transparent p-0 form-control italic-placeholder" placeholder="Governance Address" />
                    </div>
                  </div>
                </div>
                <div className="btnGroup">
                  <button type='button' className="btn rounded-0 btn--primary-outlined">
                    Cancel
                  </button>
                  <button type='submit' className="btn rounded-0 primary-box btn--primary">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
