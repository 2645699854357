import React from 'react'
import TopRightProfile from '../../TopRightProfile';
import { axiosClientPy } from '../../../utils';
import { toast } from 'react-toastify';

export default function CreateProjectDeFiStep1({ projectData, setStep }) {
  const [input, setInput] = React.useState({
    name: projectData?.name ? projectData.name : '',
    nativeAddress: projectData?.native_token_address ? projectData.native_token_address : ''
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      Authorization: "Token " + window.localStorage.getItem("USER_TOKEN")
    }
    try {
      const mData = new FormData();
      mData.append("project_id", projectData?.uuid);
      mData.append("name", input.name);
      mData.append("native_token_address", input.nativeAddress);
      mData.append("step_count", 4);
      const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
      setStep(2);
    } catch (error) {
      toast.error("Internal Server Error!!!")
    }
  }
  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  }
  return (
    <section className="pe-0 main-content">
      <div className="pt-0 main-content-inner box-steps--illustration">
        <TopRightProfile />
        {/*----- Completion stats row -----*/}
        <div className="primary-box completion-row completion-row--back">
          <div className="completion-row__left">
            <a href={window.location.href.replace("&scType=DeFi", "")} className="redirect-back">
              <img src="./assets/images/icons/back-arrow.svg" alt="Back arrow" />
            </a>
            <div >
              <h1 className="fw600 text-white">DeFi</h1>
              <p className="m-0 grayText">
                Merging technology to remove third parties and centralized
                institutions from financial transactions.
              </p>
            </div>
          </div>
          <div className="completion-row__right">
            <img className="logo-crypto" src="./assets/images/defi-logo.svg" alt="" />
            {/* <button className="btn rounded-0 btn--white-outlined">
              <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3337 10C18.3337 10 15.8337 15 10.0003 15C4.16699 15 1.66699 10 1.66699 10C1.66699 10 4.16699 5 10.0003 5C15.8337 5 18.3337 10 18.3337 10Z" strokeLinecap="round" />
                <circle cx={10} cy={10} r="2.5" strokeLinecap="round" />
              </svg>
              <span className="fw600">Show code</span>
            </button> */}
          </div>
        </div>
        {/* ============================== */}
        <div className="box-steps">
          <h2 className="text-center mb-0 fst-italic fw700">
            Assign your Token a unique Name!
          </h2>
          <p className="text-center grayText--lighter">
            Are you ready to make your token stand out in the crowd? Let your creativity flourish and give your token a name that resonates, intrigues, and leaves a lasting impact in the world of digital assets.
          </p>
          <div className="w-100 form-box form-box--purple">
            <form onSubmit={onSubmit} className="form">
              <div className="top">
                <div className="form-group">
                  <div className="form-group__inner">
                    <label htmlFor className="label">
                      <div className="primary-box label__icon">
                        <img src="./assets/images/icons/nft-name.svg" alt="User icon" />
                      </div>
                    </label>
                    <input type="text" name='name' value={input.name} onChange={onChange} className="rounded-0 border-0 bg-transparent p-0 form-control italic-placeholder" placeholder="Write Token Name" />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group__inner">
                    <label htmlFor className="label">
                      <div className="primary-box label__icon">
                        <img src="./assets/images/icons/nft-name.svg" alt="User icon" />
                      </div>
                    </label>
                    <input type="text" name='nativeAddress' value={input.nativeAddress} onChange={onChange} className="rounded-0 border-0 bg-transparent p-0 form-control italic-placeholder" placeholder="Enter Native Token Address" />
                  </div>
                </div>
              </div>
              <div className="btnGroup">
                <button type="button" className="btn rounded-0 btn--primary-outlined">
                  Cancel
                </button>
                <button type="submit" className="btn rounded-0 primary-box btn--primary">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
