import React from 'react'

export default function VerifiedSuccessModal({ name }) {
  return (
    <div className="modal top-right-modal fade" id="verifiedModal" aria-hidden="true" aria-labelledby="verifiedModalLabel2" tabIndex={-1}>
      <div className="m-0 ms-auto modal-dialog">
        <div className="border-0 rounded-0 modal-content">
          <div className="modal-body">
            <div className="modal-wrapper">
              <button type="button" className="shadow-none btn-close" data-bs-dismiss="modal" aria-label="Close" />
              <div className="logo-image">
                <img src="./assets/images/logo-black.svg" alt="BlockBrew Logo" />
              </div>
              <h2 className="text-center fw600">
                Your {name} OCSC Verified Successfully!
              </h2>
              <img src="./assets/images/animations/deploy/deploy.svg" alt="" />
              <button className="w-100 btn primary-box fz14 fw700 rounded-0 text-capitalize btn--primary" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#showCodeModal">
                Show Code
              </button>
              <img className="spiral-img" src="./assets/images/animations/spiral-in-modal/spiral-in-modal.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
