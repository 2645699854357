import React, { useEffect, useState } from 'react'
import LeftSidebar from '../components/LeftSidebar'
import RightSidebar from '../components/RightSidebar'
import useFetch from "../utils/hooks/useFetch";
import Loading from "../components/Loading";
import ProjectListWidget from '../components/ProjectListWidget';
import MultiLineChart from '../components/MultiLineChart';
import AboutProjectWidget from '../components/AboutProjectWidget';
import { getDateFormat } from '../utils';
import SearchComponent from '../components/SearchComponent';

export default function Dashboard() {
  const { data, loading } = useFetch("/project/project-creation");
  // const data = [];
  // const loading = false;

  const mQuery =
    `// saleReportChart
  if (document.getElementById("canvasBar")) {
    var ctx_bar = document.getElementById("canvasBar").getContext("2d");

    var gradient3 = ctx_bar.createLinearGradient(0, 0, 170, 0);
    gradient3.addColorStop(0, "rgba(253, 231, 253, 0.67)");
    gradient3.addColorStop(1, "rgba(225, 214, 255, 0.53)");

    var barChartData = {
      labels: [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007],
      datasets: [
        {
          label: "",
          barPercentage: 0.8, // Adjust the width of the bars
          categoryPercentage: 0.8, // Adjust the space between the bars
          backgroundColor: "#E24C4D",
          borderWidth: 0,
          data: [120, 50, 60, 70, 30, 150, 200, 170],
        },
        {
          label: "",
          barPercentage: 0.8,
          categoryPercentage: 0.8,
          backgroundColor: "#282828",
          borderWidth: 0,
          data: [80, 140, 100, 60, 150, 100, 140, 160],
        },
      ],
    };

    var chartOptions = {
      responsive: false,
      maintainAspectRatio: false,
      title: {
        display: false,
      },
      scales: {
        x: {
          beginAtZero: true,
          grid: {
            color: "#f7f8fe",
          },
          ticks: {
            color: "#8F92A1",
          },
          border: {
            color: "#f7f8fe",
          },
        },

        y: {
          beginAtZero: true,
          labels: [100, 200, 300, 400, 500],
          grid: {
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            minRotation: 90,
            padding: 10,
            color: "#8F92A1",
          },
          border: {
            color: "#f7f8fe",
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    var myBar = new Chart(ctx_bar, {
      type: "bar",
      data: barChartData,
      options: chartOptions,
    });
  }


  if (document.getElementById("polarChartProj2")) {
    let progPieChart = document.getElementById("polarChartProj2").getContext("2d");

    let data = {
      labels: ["Founder", "Reserve Fund", "Investor", "Community"],
      datasets: [
        {
          label: "",
          data: [200000, 50000, 10000, 40000],
          backgroundColor: ["#EBEFF4", "#D2F4FF", "#616C7C", "#EFDFEF"],
        },
      ],
    };

    let maxVal1 = 0;
    for (var i = 0; i < data.datasets[0].data.length; i++) {
      if (data.datasets[0].data[i] > maxVal1) {
        maxVal1 = data.datasets[0].data[i];
      }
    }

    var myChart = new Chart(progPieChart, {
      plugins: [ChartDataLabels],
      type: "polarArea",
      data: data,
      options: {
        borderWidth: 0,
        responsive: true,
        scales: {
          r: {
            min: -maxVal1,
            max: maxVal1,
            beginAtZero: true,
            ticks: {
              display: false, // Remove vertical numbers
            },
            grid: {
              display: false, // Removes the circulair lines
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            formatter: (value, polarData1Ctx) => {
              let sum = 0;
              let dataArr = polarData1Ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += data;
              });
              let percentage = (value / 1000).toFixed(0) + "K";
              return percentage;
            },
            color: "#0D121C",
            font: {
              weight: "400",
              size: 14,
            },
          },
        },
      },
    });
  }

  // sparkLine01 chart
  if (document.getElementById("sparkLine1")) {
    var sparkLine1_options = {
      responsive: true,
      maintainAspectRatio: true,
      animation: {
        easing: "easeInOutQuad",
        duration: 520,
      },

      elements: {
        line: {
          tension: 0.4,
        },
      },

      scales: {
        x: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          ticks: {
            display: false,
          },
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
      },

      plugins: {
        legend: {
          display: false,
        },
      },
    };

    var sparkLineLabels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    /* Sparkline data */
    var sL1_data = [
      "1000",
      "500",
      "1500",
      "2500",
      "1000",
      "1500",
      "2000",
      "2000",
      "2500",
      "1000",
      "2000",
    ];

    var sparkLine1_data = {
      labels: sparkLineLabels,
      datasets: [
        {
          label: "",
          borderColor: "#282828",
          backgroundColor: "transparent",
          pointBackgroundColor: "white",
          data: sL1_data,
          fill: "origin",
          borderWidth: 2,
          pointRadius: 0,
          hitRadius: 25,
          pointHoverRadius: 6,
          pointHoverBackgroundColor: "#282828",
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 6,
        },
      ],
    };

    var sparkLine1 = document.getElementById("sparkLine1").getContext("2d");
    var sparkLine1Instance = new Chart(sparkLine1, {
      type: "line",
      data: sparkLine1_data,
      options: sparkLine1_options,
      plugins: [],
    });
  }

  // sparkLine02 chart
  if (document.getElementById("sparkLine2")) {
    var sparkLine2_options = {
      responsive: true,
      maintainAspectRatio: true,
      animation: {
        easing: "easeInOutQuad",
        duration: 520,
      },

      elements: {
        line: {
          tension: 0.4,
        },
      },

      scales: {
        x: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          ticks: {
            display: false,
          },
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
      },

      plugins: {
        legend: {
          display: false,
        },
      },
    };

    var sparkLineLabels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    /* Sparkline data */
    var sL1_data = [
      "2500",
      "1500",
      "2000",
      "1000",
      "1500",
      "2500",
      "2000",
      "1000",
      "2000",
      "2500",
      "2000",
    ];

    var sparkLine2_data = {
      labels: sparkLineLabels,
      datasets: [
        {
          label: "",
          borderColor: "#282828",
          backgroundColor: "transparent",
          pointBackgroundColor: "white",
          data: sL1_data,
          fill: "origin",
          borderWidth: 2,
          pointRadius: 0,
          hitRadius: 25,
          pointHoverRadius: 6,
          pointHoverBackgroundColor: "#282828",
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 6,
        },
      ],
    };

    var sparkLine2 = document.getElementById("sparkLine2").getContext("2d");
    var sparkLine2Instance = new Chart(sparkLine2, {
      type: "line",
      data: sparkLine2_data,
      options: sparkLine2_options,
      plugins: [],
    });
  }
  // sparkLine03 chart
  if (document.getElementById("sparkLine3")) {
    var sparkLine3_options = {
      responsive: true,
      maintainAspectRatio: true,
      animation: {
        easing: "easeInOutQuad",
        duration: 520,
      },

      elements: {
        line: {
          tension: 0.4,
        },
      },

      scales: {
        x: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          ticks: {
            display: false,
          },
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
      },

      plugins: {
        legend: {
          display: false,
        },
      },
    };

    var sparkLineLabels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    /* Sparkline data */
    var sL1_data = [
      "1000",
      "2000",
      "1500",
      "2000",
      "2500",
      "500",
      "1000",
      "1500",
      "2000",
      "1000",
      "2500",
    ];

    var sparkLine3_data = {
      labels: sparkLineLabels,
      datasets: [
        {
          label: "",
          borderColor: "#282828",
          backgroundColor: "transparent",
          pointBackgroundColor: "white",
          data: sL1_data,
          fill: "origin",
          borderWidth: 2,
          pointRadius: 0,
          hitRadius: 25,
          pointHoverRadius: 6,
          pointHoverBackgroundColor: "#282828",
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 6,
        },
      ],
    };

    var sparkLine3 = document.getElementById("sparkLine3").getContext("2d");
    var sparkLine3Instance = new Chart(sparkLine3, {
      type: "line",
      data: sparkLine3_data,
      options: sparkLine3_options,
      plugins: [],
    });
  }
  // sparkLine04 chart
  if (document.getElementById("sparkLine4")) {
    var sparkLine4_options = {
      responsive: true,
      maintainAspectRatio: true,
      animation: {
        easing: "easeInOutQuad",
        duration: 520,
      },

      elements: {
        line: {
          tension: 0.4,
        },
      },

      scales: {
        x: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          ticks: {
            display: false,
          },
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
      },

      plugins: {
        legend: {
          display: false,
        },
      },
    };

    var sparkLineLabels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    /* Sparkline data */
    var sL1_data = [
      "500",
      "1000",
      "2000",
      "1500",
      "2500",
      "2000",
      "1500",
      "2000",
      "1000",
      "2500",
      "1000",
    ];

    var sparkLine4_data = {
      labels: sparkLineLabels,
      datasets: [
        {
          label: "",
          borderColor: "#282828",
          backgroundColor: "transparent",
          pointBackgroundColor: "white",
          data: sL1_data,
          fill: "origin",
          borderWidth: 2,
          pointRadius: 0,
          hitRadius: 25,
          pointHoverRadius: 6,
          pointHoverBackgroundColor: "#282828",
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 6,
        },
      ],
    };

    var sparkLine4 = document.getElementById("sparkLine4").getContext("2d");
    var sparkLine4Instance = new Chart(sparkLine4, {
      type: "line",
      data: sparkLine4_data,
      options: sparkLine4_options,
      plugins: [],
    });
  }

  // PROGRESS-PIE chart using chart.js - https://www.chartjs.org/ //
  if (document.getElementById("progressInfoChart1")) {
    const progressInfoChartData1 = {
      labels: ["Resolved", "Pending"],
      datasets: [
        {
          label: "",
          data: [150000, 50000],
          backgroundColor: ["#EFDFEF", "rgba(0,0,0,0)"],
        },
      ],
    };

    let progressInfoChart1Ctx = document
      .getElementById("progressInfoChart1")
      .getContext("2d");

    var progressInfoChart1 = new Chart(progressInfoChart1Ctx, {
      plugins: [ChartDataLabels],
      type: "doughnut",
      data: progressInfoChartData1,
      options: {
        borderWidth: 0,
        responsive: true,
        rotation: -90,
        cutout: "50%",
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            // enabled: false
          },
          datalabels: {
            formatter: (value, progressInfoChart1Ctx) => {
              let sum = 0;
              let dataArr = progressInfoChart1Ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += data;
              });
              let percentage = (value / 1000).toFixed(0) + "K";
              return percentage;
            },
            color: ["#0D121C", "#0D121C"],
            font: {
              weight: "normal",
              size: 12,
            },
            anchor: "start",
            align: "end",
          },
        },
      },
    });
  }

  // Toggle btw services' & projects' GRIDS on dropdown-item selection //
  $(".dd-item-custom").on("click", function () {
    // Remove the "dd-active" class from all dropdown items
    $(".dd-item-custom").removeClass("dd-active");
    $(".dd-item-line").removeClass("dd-active");

    // Get the selected class and add the "dd-active" class to the clicked item
    var selectedClass = $(this).data("class");
    
    $(this).addClass("dd-active");

    // Toggle the selected class in div.toggle-grid
    $("#toggle-grid")
      .removeClass()
      .addClass("toggle-grid")
      .addClass(selectedClass);

    $("#toggle-line-chart")
      .removeClass()
      .addClass("toggle-line-chart")
      .addClass(selectedClass);

    if (selectedClass == "product-grid") {
      $("#js--deploy").html("Add new product");
      $("#dropdownMenuButton").html("Products");
      $(".dd-item-line").first().addClass("dd-active")
      $("#ddLineMenuBtn").html("Products");
    } else {
      $("#js--deploy").html("Create new services");
      $("#dropdownMenuButton").html("Services");
      $(".dd-item-line").last().addClass("dd-active")
      $("#ddLineMenuBtn").html("Services");
    }
  });
  // =================================================================== //

  // Toggle btw services' & projects' LINE-CHARTS on dropdown-item selection //
  $(".dd-item-line").on("click", function () {
    // Remove the "dd-active" class from all dropdown items
    $(".dd-item-line").removeClass("dd-active");
    $(".dd-item-custom").removeClass("dd-active");

    // Get the selected class and add the "dd-active" class to the clicked item
    var selectedClass = $(this).data("class");

    $(this).addClass("dd-active");
    
    // Toggle the selected class in div.toggle-line-chart
    $("#toggle-line-chart")
      .removeClass()
      .addClass("toggle-line-chart")
      .addClass(selectedClass);
    
    $("#toggle-grid")
      .removeClass()
      .addClass("toggle-grid")
      .addClass(selectedClass);

      if (selectedClass == "product-grid") {
        $("#js--deploy").html("Add new product");
        $("#dropdownMenuButton").html("Products");
        $("#ddLineMenuBtn").html("Products");
        $(".dd-item-custom").first().addClass("dd-active")
      } else {
        $("#js--deploy").html("Create new services");
        $("#dropdownMenuButton").html("Services");
        $("#ddLineMenuBtn").html("Services");
        $(".dd-item-custom").last().addClass("dd-active")
      }
  });
  // =================================================================== //

  // Quick Instructions //
  $(document).ready(function () {
    // Show/hide floating chat screen - roadmap screen
    $(".floating-chat-btn").click(function () {
      $(".chat-panel-js").addClass("visible");
    });
  
    $(".close-btn-js").click(function () {
      $(".chat-panel-js").removeClass("visible");
    });
  
    $(".instructions .floating-chat-btn, .closs-panel").click(function () {
      $(".video-panel").toggleClass("active-js");
  
      $(".instructions .floating-chat-btn").css("pointerEvents", function () {
        return $(".video-panel").hasClass("active-js") ? "none" : "auto";
      });
      $(".closs-panel").css("display", function () {
        return $(".video-panel").hasClass("active-js") ? "inline-block" : "none";
      });
    });
  
    $(".btn-dismiss").click(function () {
      $(".instructions").hide("50");
    });
  
    $(".createProject").on("ended", function () {
      $(this)
        .closest(".video-wrap")
        .find(".btn-Next")
        .css("display", "inline-flex");
    });
  
    var accordionCounter = 0;
  var $lineSpan = $(".line span");

  // Increment accordionCounter when btn-Next or btn-skip is clicked
  $(".btn-Next, .btn-skip").click(function () {
    var $currentAccordion = $(this).closest(".accordion-item");
    var $nextAccordion = $currentAccordion.next(".accordion-item");
    var $nextVideo = $nextAccordion.find("video");
    var $currentVideo = $currentAccordion.find("video");

    if ($nextAccordion.length) {
      // Pause the video in the current accordion
      $currentVideo.get(0).pause();

      $currentAccordion.removeClass("show");
      $nextAccordion.addClass("show");
      $nextAccordion.find(".accordion-button").trigger("click");

      // Autoplay the video in the next accordion
      $nextVideo.get(0).play();

      accordionCounter++;
      var widthPercentage = accordionCounter * 20 + "%";
      $lineSpan.css("width", widthPercentage);
    }
  });

  // Prevent closing all accordions
  $(".accordion-item .accordion-button").click(function () {
    var $currentAccordion = $(this).closest(".accordion-item");
    var isExpanded = $currentAccordion.hasClass("show");
    var $openAccordions = $(".accordion-item.show");

    if (!isExpanded && $openAccordions.length === 1) {
      // Do not close the only open accordion
      return false;
    }
  });

  // At least one accordion stays opened on page load
  var $openAccordion = $(".accordion-item.show");

  if ($openAccordion.length === 0) {
    var $firstAccordion = $(".accordion-item:first-child");
    $firstAccordion.addClass("show");

    // Autoplay the video in the first accordion
    var $firstVideo = $firstAccordion.find("video");
    $firstVideo.get(0).play();
  }

  // Event handler for when an accordion is opened
  $(".accordion-button").click(function () {
    var $currentAccordion = $(this).closest(".accordion-item");
    var $currentVideo = $currentAccordion.find("video");

    // Pause the video of the currently opened accordion
    $(".accordion-item.show video").each(function () {
      if (!$(this).is($currentVideo)) {
        $(this).get(0).pause();
      }
    });

    // Autoplay the video of the newly opened accordion
    $currentVideo.get(0).play();
  });
});
`;

  useEffect(() => {
    if (data) {
      const script = document.createElement("script");
      script.innerHTML = mQuery;
      script.id = "dashboardScript";
      document.body.append(script);

      return () => {
        document.getElementById("dashboardScript").remove();
      }
    }
  }, [data]);

  return (
    <div>
      <Loading loading={loading} />
      <div className="mob-menu-btn"><span /><span /><span /></div>
      <main className="page-wrapper">
        {/*------- Left Sidebar  -------*/}
        <LeftSidebar active="dashboard" />
        {/* =========================== */}
        {/*------- Middle content -------*/}
        <section className="main-content">
          <div className="main-content-inner">
            <div className="title-top-row">
              <div className="title-top-row__left">
                <h1 className="lh-1 fw600">Dashboard is Updated!</h1>
                <p>
                  <img
                    src="assets/images/icons/last-visited.svg"
                    alt="Calendar Icon denoting when was last updated"
                  />
                  <span>Last visited {getDateFormat(new Date())[2]} - {getDateFormat(new Date())[3]}</span>
                </p>
              </div>
              <SearchComponent />
            </div>
            {/*------ Multi line chart widget ------*/}
            <MultiLineChart data={data} />
            {/* =================================== */}
            {/*------- Sale & Activities widgets ------*/}
            <div className="sale-activities">
              {/* Sale widget */}
              <div className="bg-white sale-activities__left">
                <div className="top-row">
                  <div className="title-row">
                    <h2 className="mb-0 lh1-5 fw500">Sales Report</h2>
                    {/* <div className="mb-0">
                      <span>Avg Sale rate -</span>
                      <div className="rounded-circle imgCover trending-up">
                        <svg width={13} height={7} viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.313 1L7.13627 4.95835L4.93796 2.87504L1.64062 6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8.67578 1H11.3137V3.5" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                      <span className="ms-1 fw700 greenText">105.23%</span>
                    </div> */}
                  </div>
                  <div className="dropdown">
                    <button className="btn mb-2 border-0 dropdown-toggle btn-dd btn-dd--primary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Year
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item" href="#">Day</a></li>
                      <li><a className="dropdown-item" href="#">Week</a></li>
                      <li>
                        <a className="dropdown-item" href="#">Year</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <ul className="list-inline mb-0 legends">
                  <li>
                    <div className="pair">
                      <div className="pair__color pair__color--primary" />
                      <div className="pair__text">
                        Active Project(s) <br />
                        <b className="fw700">200k</b>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="pair">
                      <div className="pair__color pair__color--red" />
                      <div className="pair__text">
                        Pending Project(s) <br />
                        <b className="fw700">200k</b>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="bar-container illustration2">
                  {
                    data?.length > 0 ?
                      <canvas id="canvasBar" style={{ minHeight: '325px', height: '325px', width: '100%' }} />
                      :
                      <img class="mx-auto" src="../assets/images/new/meditating.svg" height="80%" />
                  }
                </div>
              </div>
              {/* Activities widget */}
              <div className="primary-box sale-activities__right">
                <div className="top-row">
                  <div className="title-row">
                    <h2 className="mb-0 lh1-5 fw500">Recent Activities</h2>
                  </div>
                  <div className="dropdown">
                    <button className="btn mb-2 border-0 dropdown-toggle btn-dd btn-dd--red" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      12hrs
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item" href="#">1hr</a></li>
                      <li><a className="dropdown-item" href="#">12hrs</a></li>
                      <li>
                        <a className="dropdown-item" href="#">24hrs</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <ul className="list-unstyled mb-0 activities">
                  {
                    data?.map(ele => (
                      <li>
                        <div className="activity">
                          <div className="activity__top">
                            <img src="assets/images/icons/bar-chart-icon.svg" alt="" />
                            <h3 className="fw500 m-0">New Project Added</h3>
                          </div>
                          <p className="mb-0">
                            {ele.name} | Project Type: {ele.project_type} | Status: {ele.project_status === "in_progress" ? "In progress" : (ele.project_status === "verified" ? "Verified" : "Deployed")}.
                          </p>
                          <div className="time">Date {getDateFormat(ele.created_at)[0]} - {getDateFormat(ele.created_at)[0]}</div>
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
            {/* ====================================== */}
            {/*-------- Project List widget --------*/}
            <ProjectListWidget data={data} />
            {/* =================================== */}
            {/*-------- About Projects widget --------*/}
            <AboutProjectWidget data={data} />
            {/* ===================================== */}

            {/* Floating Instructions */}
            <div className="instructions" style={{ display: data?.length > 0 ? 'none' : 'block' }}>
              <a href="javascript:void(0)" className="closs-panel">
                <img src="./../assets/images/icons/icon-close-white.svg" alt="" />
              </a>
              <button className="btn rounded-0 ts02 border-0 primary-box floating-chat-btn">
                <div className="w-100 text">
                  <strong className="fz18 fw600">Quick Instructions!</strong>
                  <span className="fz12">You can now get tips in a single click</span>
                </div>
              </button>
              <div className="primary-box video-panel">
                <div className="d-flex line"><span /></div>
                <div className="accordion accordion-flush" id="accordionExample">
                  <div className="border-0 accordion-item">
                    <h2 className="accordion-header">
                      <button className="accordion-button ts03 fz12 purple" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Dashboard, I little help.
                        <span className="arrow primary-box ms-auto">
                          <img src="../assets/images/icons/arrow-right.svg" alt="" />
                        </span>
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p className="fz10 text-white">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                          dolore magna aliquam erat volutpat. Ut wisi enim
                        </p>
                        <div className="video-wrap">
                          <div className="btn-set">
                            <button className="btn primary-box rounded-0 fz10 text-white btn-Next" type="button">
                              Next
                            </button>
                            <button className="btn primary-box rounded-0 fz10 text-white btn-skip" type="button">
                              Skip
                            </button>
                          </div>
                          <video className="createProject" preload controls>
                            <source src="./../assets/videos/mov/filter-and-action-button-dashboard.mov" />
                            <source src="./../assets/videos/mp4/filter-and-action-button-dashboard.mp4" />
                            Your browser does not support the video.
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-0 accordion-item">
                    <h2 className="accordion-header">
                      <button className="accordion-button ts03 fz12 blue collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Create a project from scratch!
                        <span className="arrow primary-box ms-auto">
                          <img src="../assets/images/icons/arrow-right.svg" alt="" />
                        </span>
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p className="fz10 text-white">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                          dolore magna aliquam erat volutpat. Ut wisi enim
                        </p>
                        <div className="video-wrap">
                          <div className="btn-set">
                            <button className="btn primary-box rounded-0 fz10 text-white btn-Next" type="button">
                              Next
                            </button>
                            <button className="btn primary-box rounded-0 fz10 text-white btn-skip" type="button">
                              Skip
                            </button>
                          </div>
                          <video className="createProject" preload controls>
                            <source src="./../assets/videos/mov/create-project.mov" />
                            <source src="./../assets/videos/mp4/create-project.mp4" />
                            Your browser does not support the video.
                          </video>
                          {/* <video width="320" height="240" controls>
                    <source src="movie.mp4" type="video/mp4">
                    <source src="movie.ogg" type="video/ogg">
                  </video> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-0 accordion-item">
                    <h2 className="accordion-header">
                      <button className="accordion-button ts03 fz12 yellow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Let’s start with Products.
                        <span className="arrow primary-box ms-auto">
                          <img src="../assets/images/icons/arrow-right.svg" alt="" />
                        </span>
                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p className="fz10 text-white">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                          dolore magna aliquam erat volutpat. Ut wisi enim
                        </p>
                        <div className="video-wrap">
                          <div className="btn-set">
                            <button className="btn primary-box rounded-0 fz10 text-white btn-Next" type="button">
                              Next
                            </button>
                            <button className="btn primary-box rounded-0 fz10 text-white btn-skip" type="button">
                              Skip
                            </button>
                          </div>
                          <video className="createProject" preload controls>
                            <source src="./../assets/videos/mov/create-project-by-product.mov" />
                            <source src="./../assets/videos/mp4/create-project-by-product.mp4" />
                            Your browser does not support the video.
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-0 accordion-item">
                    <h2 className="accordion-header">
                      <button className="accordion-button ts03 fz12 red collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Let’s start with Services.
                        <span className="arrow primary-box ms-auto">
                          <img src="../assets/images/icons/arrow-right.svg" alt="" />
                        </span>
                      </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p className="fz10 text-white">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                          dolore magna aliquam erat volutpat. Ut wisi enim
                        </p>
                        <div className="video-wrap">
                          <div className="btn-set">
                            <button className="btn primary-box rounded-0 fz10 text-white btn-Next" type="button">
                              Next
                            </button>
                            <button className="btn primary-box rounded-0 fz10 text-white btn-skip" type="button">
                              Skip
                            </button>
                          </div>
                          <video className="createProject" preload controls>
                            <source src="./../assets/videos/mov/create-project-via-services.mov" />
                            <source src="./../assets/videos/mp4/create-project-via-services.mp4" />
                            Your browser does not support the video.
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-0 accordion-item">
                    <h2 className="accordion-header">
                      <button className="accordion-button ts03 fz12 green collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        PDF Report, encircle your analytics.
                        <span className="arrow primary-box ms-auto">
                          <img src="../assets/images/icons/arrow-right.svg" alt="" />
                        </span>
                      </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p className="fz10 text-white">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                          dolore magna aliquam erat volutpat. Ut wisi enim
                        </p>
                        <div className="video-wrap">
                          <div className="btn-set">
                            <button className="btn primary-box rounded-0 fz10 text-white btn-Next" type="button">
                              Next
                            </button>
                            <button className="btn primary-box rounded-0 fz10 text-white btn-skip" type="button">
                              Skip
                            </button>
                          </div>
                          <video className="createProject" preload controls>
                            <source src="./../assets/videos/mov/report-video.mov" />
                            <source src="./../assets/videos/mp4/report-video.mp4" />
                            Your browser does not support the video.
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <a href="javascript:void(0)" className="btn rounded-0 border-0 bg-transparent px-0 fw700 fw12 text-white btn-dismiss">
                    Dismiss Introduction
                  </a>
                </div>
              </div>
            </div>



          </div>
        </section>
        {/* ====================================== */}
        {/*------- Right Sidebar  -------*/}
        <RightSidebar location="Dashboard" />
        {/* ============================ */}
      </main>
    </div>
  )
}
