import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateProject from './pages/CreateProject';
import Dashboard from './pages/Dashboard';
import EditProfile from './pages/EditProfile';
import Login from './pages/Login';
import Profile from './pages/Profile';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LiveSupport from './pages/LiveSupport';
import Reports from './pages/Reports';
import TransactionHistory from './pages/TransactionHistory';
import Tutorials from './pages/Tutorials';
import TutorialView from './pages/TutorialView';
import Exams from './pages/Exams';
import Certificates from './pages/Certificates';
import SecretLogin from './pages/SecretLogin';

function App() {

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" />
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/profile' element={<Profile />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path='/new-project' element={<CreateProject />} />
        <Route path='/live-support' element={<LiveSupport />} />
        <Route path='/reports' element={<Reports />} />
        <Route path='/transaction-history' element={<TransactionHistory />} />
        <Route path='/tutorials' element={<Tutorials />} />
        <Route path='/tutorials/:tutorialId' element={<TutorialView />} />
        <Route path='/exams/:tutorialId' element={<Exams />} />
        <Route path='/certificates' element={<Certificates />} />
        <Route path="/secret-login" element={<SecretLogin />} />

        {/* <Route path='/' element={<Home/>} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/project' element={<Project />} />
        <Route path='/project/:project_id' element={<ViewProject />} />
        <Route path='/new-project' element={<NewProject />} /> */}
      </Routes>
    </>
  );
}

export default App;
