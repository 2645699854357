import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { DEFI } from '../../../utils/contracts/contract';
import TopRightProfile from '../../TopRightProfile';
import Loading from '../../Loading';
import ShowCodeModal from '../Modals/ShowCodeModal';
import { ContractFactory, ethers } from 'ethers';
import { APP_DATA, axiosClient, axiosClientPy, networkDetails, networksChainID } from '../../../utils';
import { toast } from 'react-toastify';
import PaymentOptionsModal from '../Modals/PaymentOptionsModal';
import useRazorpay from 'react-razorpay';

export default function CreateProjectDeFiStep8({ projectData, setStep }) {
  const Razorpay = useRazorpay();
  const headers = {
    Authorization: "Token " + window.localStorage.getItem("USER_TOKEN")
  }
  const [loading, setLoading] = useState(false);
  const [scValues, setScValues] = useState({
    name: projectData?.name ? projectData?.name : '',
    nativeAddress: projectData?.native_token_address ? projectData?.native_token_address : '',
    price: projectData?.token_price ? projectData.token_price : '',
    apyDeposit: projectData?.apy_deposit ? projectData.apy_deposit : '50',
    mainPrice: projectData?.main_price ? projectData.main_price : '',
    fee: projectData?.fee ? projectData.fee : '',
    MinimumDeposit: projectData?.minimum_deposit ? projectData.minimum_deposit === 'true' : '',
    AddSponsor: projectData?.add_sponsor ? projectData.add_sponsor === 'true' : '',
    TicketDeposit: projectData?.ticket_deposit ? projectData.ticket_deposit === 'true' : '',
    GetSponsor: projectData?.get_sponsor ? projectData.get_sponsor === 'true' : ''
  });
  const [edit, setEdit] = useState({
    tDetails: false,
    functions: false
  })

  const editTokenDetails = async () => {
    setLoading(true);
    try {
      const mData = {
        "project_id": projectData?.uuid,
        "name": scValues.name,
        "native_token_address": scValues.nativeAddress,
        "token_price": scValues.price,
        "apy_deposit": scValues.apyDeposit,
        "main_price": scValues.mainPrice,
        "fee": scValues.fee,
      }
      const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
      setEdit(p => ({ ...p, tDetails: !p.tDetails }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Internal Server Error!!!");
    }
  }

  const editFunctions = async () => {
    setLoading(true);
    try {
      const mData = {
        "project_id": projectData?.uuid,
        "minimum_deposit": scValues.MinimumDeposit.toString(),
        "add_sponsor": scValues.AddSponsor.toString(),
        "ticket_deposit": scValues.TicketDeposit.toString(),
        "get_sponsor": scValues.GetSponsor.toString()
      }
      const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
      setEdit(p => ({ ...p, functions: !p.functions }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Internal Server Error!!!");
    }

  }

  const deploy = async () => {
    if (window.ethereum) {
      setLoading(true);
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();
        let selectedChainID, selectedNetwork;
        if (projectData?.contract_network_details?.name) {
          selectedNetwork = projectData?.contract_network_details?.name
          if (selectedNetwork === "Binance") selectedChainID = networksChainID.binance;
          else if (selectedNetwork === "Ethereum") selectedChainID = networksChainID.ethereum;
          else if (selectedNetwork === "Polygon") selectedChainID = networksChainID.polygon;
        }
        await window.ethereum.request({ method: "eth_chainId" })
        if (network.chainId != selectedChainID) {
          toast.error("Please Check Your Network!!! You need to Switch into " + selectedNetwork);
          setLoading(false);
          return;
        }
        const signer = await provider.getSigner();
        const address = await signer.getAddress();

        if (scValues?.functions?.Upgradeable) {

        } else {
          const mPayload = {
            "project_id": projectData?.uuid,
            "source_code": DEFI(scValues, address)
          }
          const response = await axiosClientPy.post("/project/compile-contract", mPayload, { headers });
          const compiledContract = response.data?.data;
          if (compiledContract.bytecode && compiledContract.abi) {
            const factory = new ContractFactory(compiledContract.abi, compiledContract.bytecode, signer);
            const contract = await factory.deploy();
            await contract.waitForDeployment();
            setLoading(false);
            const mPayload2 = {
              "project_id": projectData?.uuid,
              "contract_address": contract.target,
              "project_status": "deployed"
            }
            const apiRes = await axiosClientPy.patch("/project/project-creation", mPayload2, { headers });
            window.location.reload();
          } else {
            toast.error("ABI or ByteCode is not available!!!")
            setLoading(false);
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      alert("Metamask is not available in your system.")
    }
  }

  const checkForChain = async () => {
    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const network = await provider.getNetwork();
      let selectedChainID, selectedNetwork;
      if (projectData?.contract_network_details?.name) {
        selectedNetwork = projectData?.contract_network_details?.name
        if (selectedNetwork === "Binance") selectedChainID = 97;
        else if (selectedNetwork === "Ethereum") selectedChainID = 5;
        else if (selectedNetwork === "Polygon") selectedChainID = 80001;
      }
      await window.ethereum.request({ method: "eth_chainId" })
      if (network.chainId != selectedChainID) {
        const params = networkDetails[selectedNetwork];
        const res = await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params
        })
      }
    } catch (error) {
      console.log(error, "Aditya");
    }
  }

  const web2Fun = async () => {
    let orderInfo = {};
    try {
      const apiRes = await axiosClientPy.post("/main/init-payment", { project_id: projectData?.uuid }, { headers: headers });
      orderInfo = apiRes?.data?.data;
      console.log(orderInfo);
      const options = {
        key: orderInfo.mrchant_key,
        amount: orderInfo.paise_amount,
        currency: APP_DATA.currency,
        name: "BlockBrew",
        description: "Test Transaction",
        image: window.location.host + "/assets/images/logo-white.png",
        order_id: orderInfo.razorpay_order_id,
        handler: function (response) {
          axiosClientPy.post("/main/payment-callback", response, { headers }).than((paymentCallbackResponse) => {
            window.location.reload();
          }).catch(err => {
            toast.error("Payment Failed!!!");
          })
        },
        prefill: {
          name: "Aditya Sharma",
          email: "aditya242401@gmail.com",
          contact: "6392919732",
        },
        theme: {
          color: "#000000",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        toast.error(response.error.description);
      });

      rzp1.open();
    } catch (error) {
      console.log(error, "Aditya");
    }
  }

  const web3Fun = async () => {
    if (window.ethereum) {
      setLoading(true);
      document.getElementById("paymentOptionsModalClose").click();
      try {
        const apiRes = await axiosClientPy.post("/main/init-payment", { project_id: projectData?.uuid, is_meta_transaction: true }, { headers: headers });
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();
        const signer = await provider.getSigner();
        let smart_contract, selectedNetwork;
        if (projectData?.contract_network_details?.name) {
          selectedNetwork = projectData?.contract_network_details?.name;
          if (selectedNetwork === "Binance") smart_contract = new ethers.Contract(APP_DATA.bscSM, APP_DATA.abi, signer);
          else if (selectedNetwork === "Ethereum") smart_contract = new ethers.Contract(APP_DATA.goerliSM, APP_DATA.abi, signer);
          else if (selectedNetwork === "Polygon") smart_contract = new ethers.Contract(APP_DATA.polygonSM, APP_DATA.abi, signer);
        }
        const inp = ethers.parseUnits("0.000005", "ether");
        const trans = await smart_contract.payment(inp, { value: inp });
        const dTrans = await trans.wait();
        const apiReq = {
          project_id: projectData?.uuid,
          web3_transaction_id: dTrans?.hash ? dTrans.hash : dTrans.transactionHash,
          order_id: apiRes?.data?.data?.order_id
        }
        const orderData = await axiosClientPy.post("/main/create-order", apiReq, { headers });
        window.location.reload();
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }

  return (
    <>
      <Loading loading={loading} />
      <section className="pe-0 main-content">
        <div className="pt-0 main-content-inner">
          <TopRightProfile />
          {/*----- Completion stats row -----*/}
          <div className="primary-box completion-row completion-row--back">
            <div className="completion-row__left">
              <button onClick={() => setStep(7)} className="redirect-back">
                <img src="./assets/images/icons/back-arrow.svg" alt="Back arrow" />
              </button>
              <div >
                <h1 className="fw600 text-white">DeFi</h1>
                <p className="m-0 grayText">
                  Merging technology to remove third parties and centralized
                  institutions from financial transactions.
                </p>
              </div>
            </div>
            <div className="completion-row__right">
              <img className="logo-crypto" src="./assets/images/defi-logo.svg" alt="" />
              {
                projectData?.transaction ?
                  <>
                    <button className="btn rounded-0 btn--white-outlined" data-bs-target="#showCodeModal" data-bs-toggle="modal">
                      <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.3337 10C18.3337 10 15.8337 15 10.0003 15C4.16699 15 1.66699 10 1.66699 10C1.66699 10 4.16699 5 10.0003 5C15.8337 5 18.3337 10 18.3337 10Z" strokeLinecap="round" />
                        <circle cx={10} cy={10} r="2.5" strokeLinecap="round" />
                      </svg>
                      <span className="fw600">Show code</span>
                    </button>
                    <a role='button' onClick={deploy} className="btn rounded-0 btn--deploy">
                      <span className="fw400">Deploy</span>
                    </a>
                    <a role='button' onClick={checkForChain} className="btn rounded-0 btn--deploy">
                      <span className="fw400">Check Network</span>
                    </a>

                  </>
                  :
                  <>
                    <button data-bs-target="#paymentOptionsModal" data-bs-toggle="modal" className="btn rounded-0 btn--white-outlined">
                      <span className="fw600">Proceed To Pay</span>
                    </button>

                    <span className="btn btn--notpaid">
                      <span className="btn__helper">
                        <img className="btn__lock" alt="pay first" src="../assets/images/icons/lock-dark.svg" />

                        <span className="btn__helptxt">
                          <span className="d-block mb-2">Proceed with your payment first</span>

                          <button data-bs-target="#paymentOptionsModal" data-bs-toggle="modal" className="btn btn--primary">
                            <span className="fw600">Proceed To Pay</span>
                          </button>
                        </span>
                      </span>

                      <span class="btn__text">Show code</span>
                    </span>

                    <span className="btn btn--notpaid">
                      <span className="btn__helper">
                        <img className="btn__lock" alt="pay first" src="../assets/images/icons/lock-dark.svg" />
                        <span className="btn__helptxt">
                          <span className="d-block mb-2">Proceed with your payment first</span>
                          <button data-bs-target="#paymentOptionsModal" data-bs-toggle="modal" className="btn btn--primary">
                            <span className="fw600">Proceed To Pay</span>
                          </button>
                        </span>
                      </span>
                      <span className="btn__text">Deploy</span>
                    </span>
                  </>
              }
            </div>
          </div>
          {/* ============================== */}
          <div className="overview">
            <h4 className="fw700">Overview Of DeFi</h4>
            <p className="mb-0 grayText--lighter overview-para">
              Your token name, token symbol and functions of the token you want are listed below. Have a thorough view before you proceed to pay. And then you can deploy Defi token.
            </p>
            <div className="mb-3 bg-white overview__formBox">
              <div className="top-row-cover">
                <h4 className="fw700 darkText fz14">DeFi - Details:</h4>
                <a role='button' onClick={() => { setEdit(p => ({ ...p, tDetails: !p.tDetails })) }} className="edit-overview">
                  <img src="./assets/images/icons/edit-icon.svg" alt="Edit icon" />
                </a>
              </div>
              <p className="mb-0 grayText--lighter overview-para">
                Give a unique name to your token, assign symbol and adjust decimal
                to start with your NFT 721
              </p>
              <form action className="form">
                <div className="form-group">
                  <div className="form-group__inner">
                    <label htmlFor className="label">
                      <div className="primary-box label__icon">
                        <img src="./assets/images/icons/nft-name.svg" alt="User icon" />
                      </div>
                      <strong className="fw600">Name:</strong>
                    </label>
                    <input type="text" className="border-0 rounded-0 form-control italic-placeholder" value={scValues?.name} readOnly={!edit.tDetails} onChange={(e) => setScValues(p => ({ ...p, name: e.target.value }))} />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group__inner">
                    <label htmlFor className="label">
                      <div className="primary-box label__icon">
                        <img src="./assets/images/icons/nft-name.svg" alt="User icon" />
                      </div>
                      <strong className="fw600">Token Address:</strong>
                    </label>
                    <input type="text" className="border-0 rounded-0 form-control italic-placeholder" value={scValues?.nativeAddress} readOnly={!edit.tDetails} onChange={(e) => setScValues(p => ({ ...p, nativeAddress: e.target.value }))} />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group__inner">
                    <label htmlFor className="label">
                      <div className="primary-box label__icon">
                        <img src="./assets/images/icons/nft-name.svg" alt="User icon" />
                      </div>
                      <strong className="fw600">Value:</strong>
                    </label>
                    <input type="text" className="border-0 rounded-0 form-control italic-placeholder" value={scValues?.price} readOnly={!edit.tDetails} onChange={(e) => setScValues(p => ({ ...p, price: e.target.value }))} />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group__inner">
                    <label htmlFor className="label">
                      <div className="primary-box label__icon">
                        <img src="./assets/images/icons/nft-name.svg" alt="User icon" />
                      </div>
                      <strong className="fw600">ApyDeposit (%):</strong>
                    </label>
                    <input type="text" className="border-0 rounded-0 form-control italic-placeholder" value={scValues?.apyDeposit} readOnly={!edit.tDetails} onChange={(e) => setScValues(p => ({ ...p, apyDeposit: e.target.value }))} />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group__inner">
                    <label htmlFor className="label">
                      <div className="primary-box label__icon">
                        <img src="./assets/images/icons/nft-name.svg" alt="User icon" />
                      </div>
                      <strong className="fw600">Price:</strong>
                    </label>
                    <input type="text" className="border-0 rounded-0 form-control italic-placeholder" value={scValues?.mainPrice} readOnly={!edit.tDetails} onChange={(e) => setScValues(p => ({ ...p, mainPrice: e.target.value }))} />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group__inner">
                    <label htmlFor className="label">
                      <div className="primary-box label__icon">
                        <img src="./assets/images/icons/nft-name.svg" alt="User icon" />
                      </div>
                      <strong className="fw600">Fee (%):</strong>
                    </label>
                    <input type="text" className="border-0 rounded-0 form-control italic-placeholder" value={scValues?.fee} readOnly={!edit.tDetails} onChange={(e) => setScValues(p => ({ ...p, fee: e.target.value }))} />
                  </div>
                </div>
                {edit.tDetails &&
                  <div className="mt-auto ms-auto btnGroup">
                    <button onClick={editTokenDetails} type="button" className="btn rounded-0 primary-box btn--primary edit-btn">
                      Edit
                    </button>
                  </div>
                }
              </form>
            </div>
            <div className="bg-white overview__formBox">
              <div className="top-row-cover">
                <h4 className="fw700 darkText fz14">
                  Overview Of DeFi token Functions:
                </h4>
                <a role='button' onClick={() => { setEdit(p => ({ ...p, functions: !p.functions })) }} className="edit-overview">
                  <img src="./assets/images/icons/edit-icon.svg" alt="Edit icon" />
                </a>
              </div>
              <p className="mb-0 grayText--lighter overview-para">
                Key Features of your DeFi token:
              </p>
              <form action className="form">
                <div className="mb-2 row">
                  <div className="col-6">
                    <div className="top">
                      <div className="fw600 form-group--checkbox">
                        <input id="one" type="checkbox" disabled={!edit.functions} checked={scValues?.MinimumDeposit} onChange={() => { setScValues(p => ({ ...p, MinimumDeposit: !p.MinimumDeposit })) }} />
                        <label htmlFor="one">
                          <b />
                          Minimum Deposit
                        </label>
                      </div>
                      <p className="grayText--lighter fz12 mx-auto">
                        the minimum deposit for your Defi token.
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="top">
                      <div className="fw600 form-group--checkbox">
                        <input id="two" type="checkbox" disabled={!edit.functions} checked={scValues?.AddSponsor} onChange={() => { setScValues(p => ({ ...p, AddSponsor: !p.AddSponsor })) }} />
                        <label htmlFor="two">
                          <b />
                          Add Sponsor
                        </label>
                      </div>
                      <p className="grayText--lighter fz12 mx-auto">
                        add a sponsor for your token.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-2 row">
                  <div className="col-6">
                    <div className="top">
                      <div className="fw600 form-group--checkbox">
                        <input id="three" type="checkbox" disabled={!edit.functions} checked={scValues?.TicketDeposit} onChange={() => { setScValues(p => ({ ...p, TicketDeposit: !p.TicketDeposit })) }} />
                        <label htmlFor="three">
                          <b />
                          Ticket Deposit
                        </label>
                      </div>
                      <p className="grayText--lighter fz12 mx-auto">
                        Enter the number of ticket deposits.
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="top">
                      <div className="fw600 form-group--checkbox">
                        <input id="four" type="checkbox" disabled={!edit.functions} checked={scValues?.GetSponsor} onChange={() => { setScValues(p => ({ ...p, GetSponsor: !p.GetSponsor })) }} />
                        <label htmlFor="four">
                          <b />
                          Get Sponsor
                        </label>
                      </div>
                      <p className="grayText--lighter fz12 mx-auto">
                        get a sponsor for your token.
                      </p>
                    </div>
                  </div>
                </div>
                {
                  edit.functions &&
                  <div className="mt-auto ms-auto btnGroup">
                    <button onClick={editFunctions} type='button' className="btn rounded-0 primary-box btn--primary edit-btn">
                      Edit
                    </button>
                  </div>
                }
              </form>
            </div>
            {/* <div className="mt-4 text-end btnGroup">
              <a href="#" role="button" className="btn rounded-0 primary-box btn--primary edit-btn ms-auto">
                Want services?
              </a>
            </div> */}
          </div>
        </div>
      </section>

      <PaymentOptionsModal web2Fun={web2Fun} web3Fun={web3Fun} />
      <ShowCodeModal code={DEFI(scValues, "")} />
    </>
  )
}
