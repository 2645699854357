import React, { useEffect, useState } from 'react'
import CreateProjectStep1 from '../components/CreateProject/CreateProjectStep1';
import CreateProjectStep2 from '../components/CreateProject/CreateProjectStep2';
import CreateProjectStep3 from '../components/CreateProject/CreateProjectStep3';
import LeftSidebar from '../components/LeftSidebar'
import { useSearchParams } from 'react-router-dom';
import { axiosClientPy, networks } from '../utils';
import CreateProjectERC20Step1 from '../components/CreateProject/ERC20/CreateProjectERC20Step1';
import CreateProjectERC20Step2 from '../components/CreateProject/ERC20/CreateProjectERC20Step2';
import CreateProjectERC20Step3 from '../components/CreateProject/ERC20/CreateProjectERC20Step3';
import CreateProjectERC20Step4 from '../components/CreateProject/ERC20/CreateProjectERC20Step4';
import CreateProjectERC20Step5 from '../components/CreateProject/ERC20/CreateProjectERC20Step5';
import CreateProjectERC20Step6 from '../components/CreateProject/ERC20/CreateProjectERC20Step6';

import CreateProjectERC721Step1 from '../components/CreateProject/ERC721/CreateProjectERC721Step1';
import CreateProjectERC721Step2 from '../components/CreateProject/ERC721/CreateProjectERC721Step2';
import CreateProjectERC721Step3 from '../components/CreateProject/ERC721/CreateProjectERC721Step3';
import CreateProjectERC721Step4 from '../components/CreateProject/ERC721/CreateProjectERC721Step4';
import CreateProjectERC1155Step1 from '../components/CreateProject/ERC1155/CreateProjectERC1155Step1';
import CreateProjectERC721Step5 from '../components/CreateProject/ERC721/CreateProjectERC721Step5';
import CreateProjectERC1155Step2 from '../components/CreateProject/ERC1155/CreateProjectERC1155Step2';
import CreateProjectERC1155Step3 from '../components/CreateProject/ERC1155/CreateProjectERC1155Step3';
import CreateProjectERC1155Step4 from '../components/CreateProject/ERC1155/CreateProjectERC1155Step4';
import CreateProjectERC1155Step5 from '../components/CreateProject/ERC1155/CreateProjectERC1155Step5';
import CreateProjectDeFiStep1 from '../components/CreateProject/DeFi/CreateProjectDeFiStep1';
import CreateProjectDeFiStep2 from '../components/CreateProject/DeFi/CreateProjectDeFiStep2';
import CreateProjectDeFiStep3 from '../components/CreateProject/DeFi/CreateProjectDeFiStep3';
import CreateProjectDeFiStep4 from '../components/CreateProject/DeFi/CreateProjectDeFiStep4';
import CreateProjectDeFiStep5 from '../components/CreateProject/DeFi/CreateProjectDeFiStep5';
import CreateProjectDeFiStep8 from '../components/CreateProject/DeFi/CreateProjectDeFiStep8';
import CreateProjectDeFiStep7 from '../components/CreateProject/DeFi/CreateProjectDeFiStep7';
import CreateProjectDeFiStep6 from '../components/CreateProject/DeFi/CreateProjectDeFiStep6';
import CreateProjectDeFiStep9 from '../components/CreateProject/DeFi/CreateProjectDeFiStep9';
import CreateProjectServiceType from '../components/CreateProject/Services/CreateProjectServiceType';
import CreateProjectServiceType1 from '../components/CreateProject/Services/CreateProjectServiceType1';
import CreateProjectServiceType2 from '../components/CreateProject/Services/CreateProjectServiceType2';
import CreateProjectServiceType3 from '../components/CreateProject/Services/CreateProjectServiceType3';
import useFetch from '../utils/hooks/useFetch';
import CreateProjectDaoStep1 from '../components/CreateProject/Dao/CreateProjectDaoStep1';
import CreateProjectDaoStep2 from '../components/CreateProject/Dao/CreateProjectDaoStep2';
import CreateProjectDaoStep3 from '../components/CreateProject/Dao/CreateProjectDaoStep3';
import CreateProjectDaoStep4 from '../components/CreateProject/Dao/CreateProjectDaoStep4';
import CreateProjectDaoStep5 from '../components/CreateProject/Dao/CreateProjectDaoStep5';
import CreateProjectDaoStep6 from '../components/CreateProject/Dao/CreateProjectDaoStep6';
import CreateProjectMarketplace721Step1 from '../components/CreateProject/Marketplace721/CreateProjectMarketplace721Step1';
import CreateProjectMarketplace721Step2 from '../components/CreateProject/Marketplace721/CreateProjectMarketplace721Step2';
import CreateProjectMarketplace721Step3 from '../components/CreateProject/Marketplace721/CreateProjectMarketplace721Step3';
import CreateProjectMarketplace721Step4 from '../components/CreateProject/Marketplace721/CreateProjectMarketplace721Step4';
import CreateProjectMarketplace721Step5 from '../components/CreateProject/Marketplace721/CreateProjectMarketplace721Step5';
import CreateProjectMarketplace721Step6 from '../components/CreateProject/Marketplace721/CreateProjectMarketplace721Step6';

const mScript = `
// sparkLine01 chart
if (document.getElementById("sparkLine1")) {
  var sparkLine1_options = {
    responsive: true,
    maintainAspectRatio: true,
    animation: {
      easing: "easeInOutQuad",
      duration: 520,
    },

    elements: {
      line: {
        tension: 0.4,
      },
    },

    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
    },
  };

  var sparkLineLabels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  /* Sparkline data */
  var sL1_data = [
    "1000",
    "500",
    "1500",
    "2500",
    "1000",
    "1500",
    "2000",
    "2000",
    "2500",
    "1000",
    "2000",
  ];

  var sparkLine1_data = {
    labels: sparkLineLabels,
    datasets: [
      {
        label: "",
        borderColor: "#282828",
        backgroundColor: "transparent",
        pointBackgroundColor: "white",
        data: sL1_data,
        fill: "origin",
        borderWidth: 2,
        pointRadius: 0,
        hitRadius: 25,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: "#282828",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 6,
      },
    ],
  };

  var sparkLine1 = document.getElementById("sparkLine1").getContext("2d");
  var sparkLine1Instance = new Chart(sparkLine1, {
    type: "line",
    data: sparkLine1_data,
    options: sparkLine1_options,
    plugins: [],
  });
}

// sparkLine02 chart
if (document.getElementById("sparkLine2")) {
  var sparkLine2_options = {
    responsive: true,
    maintainAspectRatio: true,
    animation: {
      easing: "easeInOutQuad",
      duration: 520,
    },

    elements: {
      line: {
        tension: 0.4,
      },
    },

    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
    },
  };

  var sparkLineLabels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  /* Sparkline data */
  var sL1_data = [
    "2500",
    "1500",
    "2000",
    "1000",
    "1500",
    "2500",
    "2000",
    "1000",
    "2000",
    "2500",
    "2000",
  ];

  var sparkLine2_data = {
    labels: sparkLineLabels,
    datasets: [
      {
        label: "",
        borderColor: "#282828",
        backgroundColor: "transparent",
        pointBackgroundColor: "white",
        data: sL1_data,
        fill: "origin",
        borderWidth: 2,
        pointRadius: 0,
        hitRadius: 25,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: "#282828",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 6,
      },
    ],
  };

  var sparkLine2 = document.getElementById("sparkLine2").getContext("2d");
  var sparkLine2Instance = new Chart(sparkLine2, {
    type: "line",
    data: sparkLine2_data,
    options: sparkLine2_options,
    plugins: [],
  });
}
// sparkLine03 chart
if (document.getElementById("sparkLine3")) {
  var sparkLine3_options = {
    responsive: true,
    maintainAspectRatio: true,
    animation: {
      easing: "easeInOutQuad",
      duration: 520,
    },

    elements: {
      line: {
        tension: 0.4,
      },
    },

    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
    },
  };

  var sparkLineLabels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  /* Sparkline data */
  var sL1_data = [
    "1000",
    "2000",
    "1500",
    "2000",
    "2500",
    "500",
    "1000",
    "1500",
    "2000",
    "1000",
    "2500",
  ];

  var sparkLine3_data = {
    labels: sparkLineLabels,
    datasets: [
      {
        label: "",
        borderColor: "#282828",
        backgroundColor: "transparent",
        pointBackgroundColor: "white",
        data: sL1_data,
        fill: "origin",
        borderWidth: 2,
        pointRadius: 0,
        hitRadius: 25,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: "#282828",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 6,
      },
    ],
  };

  var sparkLine3 = document.getElementById("sparkLine3").getContext("2d");
  var sparkLine3Instance = new Chart(sparkLine3, {
    type: "line",
    data: sparkLine3_data,
    options: sparkLine3_options,
    plugins: [],
  });
}
// sparkLine04 chart
if (document.getElementById("sparkLine4")) {
  var sparkLine4_options = {
    responsive: true,
    maintainAspectRatio: true,
    animation: {
      easing: "easeInOutQuad",
      duration: 520,
    },

    elements: {
      line: {
        tension: 0.4,
      },
    },

    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
    },
  };

  var sparkLineLabels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  /* Sparkline data */
  var sL1_data = [
    "500",
    "1000",
    "2000",
    "1500",
    "2500",
    "2000",
    "1500",
    "2000",
    "1000",
    "2500",
    "1000",
  ];

  var sparkLine4_data = {
    labels: sparkLineLabels,
    datasets: [
      {
        label: "",
        borderColor: "#282828",
        backgroundColor: "transparent",
        pointBackgroundColor: "white",
        data: sL1_data,
        fill: "origin",
        borderWidth: 2,
        pointRadius: 0,
        hitRadius: 25,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: "#282828",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 6,
      },
    ],
  };

  var sparkLine4 = document.getElementById("sparkLine4").getContext("2d");
  var sparkLine4Instance = new Chart(sparkLine4, {
    type: "line",
    data: sparkLine4_data,
    options: sparkLine4_options,
    plugins: [],
  });
}
`;

export default function CreateProject() {
  const [searchParams] = useSearchParams();
  const [step, setStep] = React.useState(null);
  const [projectData, setProjectData] = useState(null);

  React.useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = mScript;
    document?.body.append(script);
  }, [step]);

  const myRouting = async () => {
    const projectID = searchParams.get("projectID");
    const qNetwork = searchParams.get("network");

    if (projectID) {
      try {
        const apiRes = await axiosClientPy.get("/project/project-creation?project_id=" + projectID, { headers: { Authorization: "Token " + window.localStorage.getItem("USER_TOKEN") } });
        const mData = apiRes?.data?.data ? apiRes?.data?.data : null;
        if (mData) {
          setProjectData(mData);
          if (mData.contract_type_details) {
            setStep(mData.contract_type_details?.name);
          } else {
            if (mData.project_type === "service" && mData.name) {
              setStep("PBS2");
            } else {
              setStep(mData?.project_type === "product" ? "PBP" : "PBS");
            }
          }
        } else {
          setStep(1);
        }
      } catch (error) {
        console.log(error);
        setStep(1);
      }

    } else if (qNetwork) {
      if (networks.find((ele) => ele == qNetwork)) {
        setStep(2);
      }
    } else {
      setStep(1);
    }
  }

  React.useEffect(() => {
    myRouting();
  }, []);

  return (
    <section>
      <div className="mob-menu-btn"> <span /> <span /> <span /> </div>
      <main className="page-wrapper">

        <LeftSidebar active="project" />

        {step === 1 && <CreateProjectStep1 />}
        {step === 2 && <CreateProjectStep2 />}
        {step === "PBP" && <CreateProjectStep3 />}
        {step === "PBS" && <CreateProjectServiceType projectData={projectData} />}
        {step === "PBS2" && <CreateProjectService projectData={projectData} />}

        {step === "ERC20" && <CreateProjectERC20 projectData={projectData} />}
        {step === "ERC721" && <CreateProjectERC721 projectData={projectData} />}
        {step === "ERC1155" && <CreateProjectERC1155 projectData={projectData} />}
        {step === "DeFI" && <CreateProjectDeFi projectData={projectData} />}
        {step === "Dao" && <CreateProjectDao projectData={projectData} />}
        {step === "Marketplace721" && <CreateProjectMarketplace721 projectData={projectData} />}

      </main>
    </section>
  )
}

const CreateProjectService = ({ projectData }) => {
  const serviceRequest = useFetch("/project/service-request?project_id=" + projectData?.uuid);
  const [mStep, setMStep] = useState(null);

  useEffect(() => {
    if (serviceRequest.data) {
      if (serviceRequest?.data?.length > 0) {
        setMStep(3);
      } else {
        setMStep(1);
      }
    }
  }, [serviceRequest.data])

  return (
    <>
      {mStep === 1 && <CreateProjectServiceType1 projectData={projectData} step={mStep} setStep={setMStep} />}
      {mStep === 2 && <CreateProjectServiceType2 projectData={projectData} step={mStep} setStep={setMStep} />}
      {mStep === 3 && <CreateProjectServiceType3 projectData={projectData} step={mStep} setStep={setMStep} />}
    </>
  )
}

const CreateProjectERC20 = ({ projectData }) => {
  const getMyStep = () => {
    if (projectData) {
      if (projectData.contract_address) return 6;
      if (projectData?.contract_functions_details?.length > 0) return 5;
      if (projectData?.pre_mint) return 4;
      if (projectData?.token_symbol) return 3;
      if (projectData?.name) return 2;
      return 1;
    }
  }
  const [mStep, setMStep] = useState(getMyStep());

  return (
    <>
      {mStep === 1 && <CreateProjectERC20Step1 projectData={projectData} setStep={setMStep} />}
      {mStep === 2 && <CreateProjectERC20Step2 projectData={projectData} setStep={setMStep} />}
      {mStep === 3 && <CreateProjectERC20Step3 projectData={projectData} setStep={setMStep} />}
      {mStep === 4 && <CreateProjectERC20Step4 projectData={projectData} setStep={setMStep} />}
      {mStep === 5 && <CreateProjectERC20Step5 projectData={projectData} setStep={setMStep} />}
      {mStep === 6 && <CreateProjectERC20Step6 projectData={projectData} setStep={setMStep} />}
    </>
  )
}

const CreateProjectERC721 = ({ projectData }) => {
  const getMyStep = () => {
    if (projectData) {
      if (projectData.contract_address) return 5;
      if (projectData?.contract_functions_details?.length > 0) return 4;
      if (projectData?.token_symbol) return 3;
      if (projectData?.name) return 2;
      return 1;
    }
  }
  const [mStep, setMStep] = useState(getMyStep());

  return (
    <>
      {mStep === 1 && <CreateProjectERC721Step1 projectData={projectData} setStep={setMStep} />}
      {mStep === 2 && <CreateProjectERC721Step2 projectData={projectData} setStep={setMStep} />}
      {mStep === 3 && <CreateProjectERC721Step3 projectData={projectData} setStep={setMStep} />}
      {mStep === 4 && <CreateProjectERC721Step4 projectData={projectData} setStep={setMStep} />}
      {mStep === 5 && <CreateProjectERC721Step5 projectData={projectData} setStep={setMStep} />}
    </>
  )
}

const CreateProjectERC1155 = ({ projectData }) => {
  const getMyStep = () => {
    if (projectData) {
      if (projectData.contract_address) return 5;
      if (projectData?.contract_functions_details?.length > 0) return 4;
      if (projectData?.token_symbol) return 3;
      if (projectData?.name) return 2;
      return 1;
    }
  }
  const [mStep, setMStep] = useState(getMyStep());

  return (
    <>
      {mStep === 1 && <CreateProjectERC1155Step1 projectData={projectData} setStep={setMStep} />}
      {mStep === 2 && <CreateProjectERC1155Step2 projectData={projectData} setStep={setMStep} />}
      {mStep === 3 && <CreateProjectERC1155Step3 projectData={projectData} setStep={setMStep} />}
      {mStep === 4 && <CreateProjectERC1155Step4 projectData={projectData} setStep={setMStep} />}
      {mStep === 5 && <CreateProjectERC1155Step5 projectData={projectData} setStep={setMStep} />}
    </>
  )
}

const CreateProjectDeFi = ({ projectData }) => {
  const getMyStep = () => {
    if (projectData) {
      if (projectData.contract_address) return 9;
      if (projectData?.get_sponsor) return 8;
      if (projectData?.add_sponsor) return 7;
      if (projectData?.ticket_deposit) return 6;
      if (projectData?.minimum_deposit) return 5;
      if (projectData?.fee) return 4;
      if (projectData?.token_price) return 3;
      if (projectData?.name) return 2;
      return 1;
    }
  }
  const [mStep, setMStep] = useState(getMyStep());

  return (
    <>
      {mStep === 1 && <CreateProjectDeFiStep1 projectData={projectData} setStep={setMStep} />}
      {mStep === 2 && <CreateProjectDeFiStep2 projectData={projectData} setStep={setMStep} />}
      {mStep === 3 && <CreateProjectDeFiStep3 projectData={projectData} setStep={setMStep} />}
      {mStep === 4 && <CreateProjectDeFiStep4 projectData={projectData} setStep={setMStep} />}
      {mStep === 5 && <CreateProjectDeFiStep5 projectData={projectData} setStep={setMStep} />}
      {mStep === 6 && <CreateProjectDeFiStep6 projectData={projectData} setStep={setMStep} />}
      {mStep === 7 && <CreateProjectDeFiStep7 projectData={projectData} setStep={setMStep} />}
      {mStep === 8 && <CreateProjectDeFiStep8 projectData={projectData} setStep={setMStep} />}
      {mStep === 9 && <CreateProjectDeFiStep9 projectData={projectData} setStep={setMStep} />}
    </>
  )
}

const CreateProjectDao = ({ projectData }) => {
  const getMyStep = () => {
    if (projectData) {
      if (projectData.contract_address) return 6;
      if (projectData?.step_count === 7) return 5;
      if (projectData?.step_count === 6) return 4;
      if (projectData?.proposal_fee) return 3;
      if (projectData?.name) return 2;
      return 1;
    }
  }
  const [mStep, setMStep] = useState(getMyStep());

  return (
    <>
      {mStep === 1 && <CreateProjectDaoStep1 projectData={projectData} setStep={setMStep} />}
      {mStep === 2 && <CreateProjectDaoStep2 projectData={projectData} setStep={setMStep} />}
      {mStep === 3 && <CreateProjectDaoStep3 projectData={projectData} setStep={setMStep} />}
      {mStep === 4 && <CreateProjectDaoStep4 projectData={projectData} setStep={setMStep} />}
      {mStep === 5 && <CreateProjectDaoStep5 projectData={projectData} setStep={setMStep} />}
      {mStep === 6 && <CreateProjectDaoStep6 projectData={projectData} setStep={setMStep} />}
    </>
  )
}

const CreateProjectMarketplace721 = ({ projectData }) => {
  const getMyStep = () => {
    console.log(projectData);
    if (projectData) {
      if (projectData.contract_address) return 7;
      if (projectData?.step_count === 8) return 6;
      if (projectData?.step_count === 7) return 5;
      if (projectData?.step_count === 6) return 4;
      if (projectData?.step_count === 5) return 3;
      if (projectData?.name) return 2;
      return 1;
    }
  }
  const [mStep, setMStep] = useState(getMyStep());

  return (
    <>
      {mStep === 1 && <CreateProjectMarketplace721Step1 projectData={projectData} setStep={setMStep} />}
      {mStep === 2 && <CreateProjectMarketplace721Step2 projectData={projectData} setStep={setMStep} />}
      {mStep === 3 && <CreateProjectMarketplace721Step3 projectData={projectData} setStep={setMStep} />}
      {mStep === 4 && <CreateProjectMarketplace721Step4 projectData={projectData} setStep={setMStep} />}
      {mStep === 5 && <CreateProjectMarketplace721Step5 projectData={projectData} setStep={setMStep} />}
      {mStep === 6 && <CreateProjectMarketplace721Step6 projectData={projectData} setStep={setMStep} />}
    </>
  )
}