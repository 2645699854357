import React, { useEffect, useState } from 'react'
import { axiosClientPy, getDateFormat } from '../../utils';
import { toast } from 'react-toastify';
import useFetch from '../../utils/hooks/useFetch';
import Loading from "../Loading";

export default function LiveSupportStep2({ topic }) {
  const [message, setMessage] = useState("");
  const headers = { Authorization: "Token " + window.localStorage.getItem("USER_TOKEN") };
  const projects = useFetch("/project/project-creation");
  const [myData, setMyData] = useState(null);

  const createTicket = async () => {
    try {
      const mData = {
        topic,
        message,
        project_id: myData
      }
      const res = await axiosClientPy.post("/main/support-ticket", mData, { headers });
      window.location.href = "/live-support?ticketId=" + res.data?.data?.uuid;
    } catch (error) {
      console.log(error);
      toast.error("Internal Server Error!!!");
    }
  }

  return (
    <>
      <Loading loading={projects.loading} />
      <section className="main-content">
        <div className="pb-0 main-content-inner">
          <div className="title-top-row">
            <div className="title-top-row__left">
              <h1 className="lh-1 fw600">Live Support</h1>
              <p>
                <span>Write your issues here, you can attach a link or a screenshot, or file for more assistance.</span>
              </p>
            </div>
            <div className="title-top-row__right">
              <div className="position-relative form-group mb-0">
                <input role="search" type="text" className="ts03 rounded-0 form-control" placeholder="Search project or service" />
                <div className="ts03 search-icon">
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.9398 17.2693L24 22.3282L22.3282 24L17.2693 18.9398C15.3869 20.4488 13.0456 21.2696 10.6331 21.2661C4.76361 21.2661 0 16.5025 0 10.6331C0 4.76361 4.76361 0 10.6331 0C16.5025 0 21.2661 4.76361 21.2661 10.6331C21.2696 13.0456 20.4488 15.3869 18.9398 17.2693ZM16.5699 16.3926C18.0692 14.8507 18.9066 12.7838 18.9032 10.6331C18.9032 6.06321 15.2017 2.3629 10.6331 2.3629C6.06321 2.3629 2.3629 6.06321 2.3629 10.6331C2.3629 15.2017 6.06321 18.9032 10.6331 18.9032C12.7838 18.9066 14.8507 18.0692 16.3926 16.5699L16.5699 16.3926Z" />
                  </svg>
                </div>
                <a href="#" className="reset-input">
                  <svg xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="0 0 29.25 29.25">
                    <path id="Icon_ionic-md-close-circle" data-name="Icon ionic-md-close-circle" d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.574,14.574,0,0,0,18,3.375Zm7.313,19.89-2.048,2.047L18,20.047l-5.265,5.265-2.048-2.047L15.953,18l-5.265-5.265,2.048-2.047L18,15.953l5.265-5.265,2.048,2.047L20.047,18Z" transform="translate(-3.375 -3.375)" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="mb-0 chainery-card chainery-card--gray live-support">
            <div className="w-100 live-support__inner">
              <h3 className="text-center fw500 fz16">
                <img src="./../assets/images/icons//spark-icon.svg" alt="" />
                Show me suggestions
              </h3>
              <div className="bg-white w-100 listing-wrap">
                <div className="top-row p-0">
                  <div className="title-row m-0">
                    <h4 className="fw600 m-0 fz14">
                      Need help related to specific product.
                    </h4>
                  </div>
                  <div className="top-row__options">
                    <label htmlFor className="fw500">Project Type:</label>
                    <div className="dropdown">
                      <button className="btn border-0 dropdown-toggle btn-dd btn-dd--primary" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="dropdownMenuButton">
                        Projects
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item dd-item-custom dd-active" role='button' data-class="product-grid">Products</a>
                        </li>
                        <li>
                          <a className="dropdown-item dd-item-custom" role='button' data-class="service-grid">Services</a>
                        </li>
                      </ul>
                    </div>
                    <div className="dropdown">
                      <button className="btn border-0 dropdown-toggle btn-dd btn-dd--primary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Year
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">Day</a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">Week</a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">Year</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p className="fz12 primaryText">
                  You can Select multiple prduct from list and write down your
                  issue regarding your product.
                </p>
                <div className="h-100 live-grid">
                  {/* DataTable */}
                  <div id="toggle-grid" className="toggle-grid product-grid">
                    <div className="table-responsive chainery-table-structure table-structure-products">
                      <div className="mx-0 title-row">
                        <h2 className="mb-0 lh1-5 fw500">Project(s) List</h2>
                        <div className="mb-0">
                          <span>Total Projects - </span>
                          <span className="ms-1 fw700 grayText">40k</span>
                        </div>
                      </div>
                      <table id="example" className="w-100 mb-0 table">
                        <thead>
                          <tr>
                            <th className="fw600 text-start">
                              <span>Sr.No</span>
                            </th>
                            <th className="fw600 text-start">
                              <span>Product Name</span>
                            </th>
                            <th className="fw600">
                              <span>Created</span>
                            </th>
                            <th className="fw600">
                              <span> Project Type</span>
                            </th>
                            <th className="fw600">
                              <span>Status</span>
                            </th>
                            <th className="fw600">
                              <span>Select</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            projects.data?.map((ele, index) => (
                              <tr>
                                <td>
                                  <h4 className="mb-0 fw700">{index + 1}.</h4>
                                </td>
                                <td>
                                  <h4 className="mb-0 fw500">{ele.name}</h4>
                                </td>
                                <td>
                                  <div className="when-created">
                                    <div className="grayText">
                                      <img src="../assets/images/icons/calender-neutral.svg" alt="" />
                                      <span>{getDateFormat(ele.created_at)[0]}</span>
                                    </div>
                                    <div>
                                      <img src="../assets/images/icons/clock.svg" alt="" />
                                      <span>{getDateFormat(ele.created_at)[1]}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {
                                    ele.project_type == "product" ? <div className="type type--product">Product</div>
                                      : <div className="type type--service">Service</div>
                                  }
                                </td>
                                <td>
                                  <div className="status status--active">Deployed</div>
                                </td>
                                <td>
                                  <div className="fw600 m-0 form-group form-group--checkbox">
                                    <input id={ele.uuid} type="checkbox" onChange={() => { myData ? setMyData(null) : setMyData(ele.uuid) }} disabled={myData == null ? false : myData !== ele.uuid} />
                                    <label htmlFor={ele.uuid}>
                                      <b className="m-0" />
                                    </label>
                                  </div>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className="table-responsive chainery-table-structure table-structure-services">
                      <div className="mx-0 title-row">
                        <h2 className="mb-0 lh1-5 fw500">Service(s) List</h2>
                        <div className="mb-0">
                          <span>Total Services - </span>
                          <span className="ms-1 fw700 grayText">40k</span>
                        </div>
                      </div>
                      <table id="example" className="w-100 mb-0 table">
                        <thead>
                          <tr>
                            <th className="fw600 text-start">
                              <span>Sr.No</span>
                            </th>
                            <th className="fw600 text-start">
                              <span>Service Name</span>
                            </th>
                            <th className="fw600">
                              <span>Created</span>
                            </th>
                            <th className="fw600">
                              <span>Status</span>
                            </th>
                            <th className="fw600">
                              <span>Select</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <h4 className="mb-0 fw700">01.</h4>
                            </td>
                            <td>
                              <h4 className="mb-0 fw500">ICO</h4>
                            </td>
                            <td>
                              <div className="when-created">
                                <div className="grayText">
                                  <img src="../assets/images/icons/calender-neutral.svg" alt="" />
                                  <span >01-June-2023</span>
                                </div>
                                <div>
                                  <img src="../assets/images/icons/clock.svg" alt="" />
                                  <span >16hrs:44min (EST)</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="status status--active">Responded</div>
                            </td>
                            <td>
                              <div className="fw600 m-0 form-group form-group--checkbox">
                                <input id="s-one" type="checkbox" />
                                <label htmlFor="s-one">
                                  <b className="m-0" />
                                </label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4 className="mb-0 fw700">02.</h4>
                            </td>
                            <td>
                              <h4 className="mb-0 fw500">PR</h4>
                            </td>
                            <td>
                              <div className="when-created">
                                <div className="grayText">
                                  <img src="../assets/images/icons/calender-neutral.svg" alt="" />
                                  <span >01-June-2023</span>
                                </div>
                                <div>
                                  <img src="../assets/images/icons/clock.svg" alt="" />
                                  <span >16hrs:44min (EST)</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="status status--inactive">Waiting</div>
                            </td>
                            <td>
                              <div className="fw600 m-0 form-group form-group--checkbox">
                                <input id="s-two" type="checkbox" />
                                <label htmlFor="s-two">
                                  <b className="m-0" />
                                </label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4 className="mb-0 fw700">03.</h4>
                            </td>
                            <td>
                              <h4 className="mb-0 fw500">Community Engagement</h4>
                            </td>
                            <td>
                              <div className="when-created">
                                <div className="grayText">
                                  <img src="../assets/images/icons/calender-neutral.svg" alt="" />
                                  <span >01-June-2023</span>
                                </div>
                                <div>
                                  <img src="../assets/images/icons/clock.svg" alt="" />
                                  <span >16hrs:44min (EST)</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="status status--inactive">Waiting</div>
                            </td>
                            <td>
                              <div className="fw600 m-0 form-group form-group--checkbox">
                                <input id="s-three" type="checkbox" />
                                <label htmlFor="s-three">
                                  <b className="m-0" />
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-auto w-100 msg-sender">
              <ul className="list-inline suggestions">
                <li>
                  <a href="#" className="btn rounded-0 primary-box text-white fz12 btn--primary tag-s">
                    Suggestion
                  </a>
                </li>
                <li>
                  <a href="#" className="btn rounded-0 primary-box text-white fz12 btn--primary tag-s">
                    Suggestion
                  </a>
                </li>
                <li>
                  <a href="#" className="btn rounded-0 primary-box text-white fz12 btn--primary tag-s">
                    Suggestion
                  </a>
                </li>
                <li>
                  <a href="#" className="btn rounded-0 primary-box text-white fz12 btn--primary tag-s">
                    Suggestion
                  </a>
                </li>
              </ul>
              <div className="bg-white mb-0 form-group">
                <a href="#" className="border-0 rounded-0 btn p-0 ts03 btn-transparent">
                  <img src="./../assets/images/icons/file-attachment-btn-icon.svg" alt="file attachment icon" />
                </a>
                <input value={message} onChange={(e) => setMessage(e.target.value)} type="text" className="border-0 rounded-0 fz12 form-control" placeholder="Write your issue here, you can attach link or upload screenshoot or file for more assistance..." />
                <a href="#" className="border-0 rounded-0 btn p-0 ts03 btn-transparent">
                  <img src="./../assets/images/icons/microphone-icon.svg" alt="microphone icon" />
                </a>
                <a role='button' onClick={createTicket} className="rounded-0 btn p-0 ts03 btn--primary-outlined-hovered ms-1">
                  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.56322 7.88394C2.02122 8.11144 1.6848 8.51643 1.67972 9.08976C1.67622 9.4906 1.94672 10.0289 2.48872 10.2489C2.71197 10.3398 5.84655 10.7614 5.84655 10.7614C5.84655 10.7614 6.67622 13.3839 6.97522 14.3056C7.0618 14.5723 7.11114 14.7031 7.30114 14.8773C7.62347 15.1723 8.16839 15.0798 8.40405 14.8431C9.02755 14.2181 10.0131 13.2539 10.0131 13.2539L10.4278 13.5914C10.4278 13.5914 12.2696 15.0606 13.2763 15.7523C13.8691 16.1598 14.2805 16.5848 14.9476 16.5873C15.2875 16.5889 15.8326 16.4198 16.1929 16.0073C16.431 15.7348 16.5837 15.2989 16.6428 14.9081C16.7771 14.0206 18.3531 4.42394 18.3464 4.08811C18.3357 3.55227 17.885 3.2506 17.5103 3.25393C17.275 3.25643 17.0811 3.32478 16.6496 3.45644C13.3117 4.47561 2.7843 7.79144 2.56322 7.88394ZM15.0131 5.75394C15.0131 5.75394 10.6147 9.58228 8.85714 11.3439C8.29405 11.9081 8.2543 12.8764 8.2543 12.8764L7.34581 9.96976L15.0131 5.75394Z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
