import React, { useEffect, useState } from 'react'
import { axiosClientPy, getDateFormat } from '../utils';
import useFetch from '../utils/hooks/useFetch';
import NotificationPopup from './NotificationPopup';

export default function RightSidebar({ location }) {
  const [data, setData] = useState(null);
  const projects = useFetch("/project/project-creation");
  const tickets = useFetch("/main/support-ticket");

  const mScript = `
  // projectsDonutChart
  if (document.getElementById("projectsDonutChart")) {
    const projetsData = {
      labels: [
        "Ethereum Project(s)",
        "Binance Project(s)",
        "Cardano Project(s)",
        "Polygon Project(s)",
      ],
      datasets: [
        {
          label: "Data",
          data: [
            ${projects?.data?.filter(ele => ele.contract_network_details?.name === "Ethereum").length},
            ${projects?.data?.filter(ele => ele.contract_network_details?.name === "Binance").length},
            ${projects?.data?.filter(ele => ele.contract_network_details?.name === "Cardano").length},
            ${projects?.data?.filter(ele => ele.contract_network_details?.name === "Polygon").length}
          ],
          backgroundColor: ["#ffb8d0", "#fff8a8", "#e8f5e9", "#abe3fc"],
          hoverOffset: 0,
          borderRadius: [50, 50, 50, 50],
        },
      ],
    };

    const projetsConfig = {
      type: "doughnut",
      data: projetsData,
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        borderWidth: 0,
        cutout: "80%",
        rotation: 0,
      },
    };

    const projetsDonutChart = new Chart(
      document.getElementById("projectsDonutChart"),
      projetsConfig
    );
  }
  `;

  const getUserData = async () => {
    try {
      const headers = {
        Authorization: "Token " + window.localStorage.getItem("USER_TOKEN")
      }
      const res = await axiosClientPy.get("/account/get-profile", { headers });
      setData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (projects.data) {
      const script = document.createElement("script");
      script.innerHTML = mScript;
      document.body.append(script);
    }
  }, [projects.data]);

  return (
    <section className="sidebar-right">
      <div className="sidebar-right-inner">
        <div className="sidebar-right-inner__top">
          <img
            className="rounded-circle user"
            src={data?.profile_image ? data?.profile_image : "/assets/images/new/team-creative.svg"}
            alt="user display picture"
            style={{ width: "60px", height: "60px", objectFit: 'contain', borderRadius: "50%", background: '#e24c4d', border: "2px solid #24262b" }}
          />
          <div className="title-row name-info">
            <h2 className="fw700">Welcome Back! {data?.full_name}</h2>
            <p className="mb-0">
              <img
                src="assets/images/icons/last-visited.svg"
                alt="Calendar Icon denoting when was last updated"
              />
              <span>{getDateFormat(new Date())[2]} - {getDateFormat(new Date())[3]}</span>
            </p>
          </div>
          <NotificationPopup />
          <div className="dropdown">
            <a
              className="btn fw500 border-0 p-0 dropdown-toggle"
              href="#"
              role="button"
              id="languageLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              EN
            </a>
            <ul className="dropdown-menu" aria-labelledby="languageLink">
              <li>
                <a className="fw500 dropdown-item" href="#">
                  EN
                </a>
              </li>
              <li>
                <a className="fw500 dropdown-item" href="#">
                  FR
                </a>
              </li>
              <li>
                <a className="fw500 dropdown-item" href="#">
                  DU
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr className="separator" />
        {location === "CreateProject" ? (
          <div className="sidebar-right-inner__main">
            <div className="m-0 about-blockchain-projects">
              <div className="w-100 p-3 pb-0 primary-box">
                <div className="top-row">
                  <div className="title-row">
                    <h2 className="mb-0 lh1-5 fw500">About Projects</h2>
                    <div className="mb-0">
                      <span>Total Projects</span>
                      <span className="ms-1 fw700 grayText">{projects?.data?.length}</span>
                    </div>
                  </div>
                  <div className="dropdown">
                    <button className="btn mb-2 border-0 dropdown-toggle btn-dd btn-dd--red" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Year
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item" href="#">Day</a></li>
                      <li><a className="dropdown-item" href="#">Week</a></li>
                      <li>
                        <a className="dropdown-item" href="#">Year</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="about-projects">
                  <div className="chart-container donutChart">
                    <div className="donutChartInner">
                      <canvas id="projectsDonutChart" />
                    </div>
                    <div className="donutContent">
                      <div className="donutContent__label">Projects Created</div>
                      <div className="donutContent__value">{projects?.data?.length}</div>
                    </div>
                  </div>
                  <ul className="list-inline legends">
                    <li>
                      <div className="pair">
                        <div className="pair__color pair__color--off-white" />
                        <div className="pair__text">
                          Ethereum Project(s)
                          <br />
                          <b className="fw700">{projects?.data?.filter(ele => ele.contract_network_details?.name === "Ethereum").length}</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="pair">
                        <div className="pair__color pair__color--blue" />
                        <div className="pair__text">
                          Cardano Project(s)
                          <br />
                          <b className="fw700">{projects?.data?.filter(ele => ele.contract_network_details?.name === "Cardano").length}</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="pair">
                        <div className="pair__color pair__color--pink" />
                        <div className="pair__text">
                          Binance Project(s) <br />
                          <b className="fw700">{projects?.data?.filter(ele => ele.contract_network_details?.name === "Binance").length}</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="pair">
                        <div className="pair__color pair__color--gray" />
                        <div className="pair__text">
                          Polygon Project(s) <br />
                          <b className="fw700">{projects?.data?.filter(ele => ele.contract_network_details?.name === "Polygon").length}</b>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="p-0 chainery-card chainery-card--gray mt-3">
              <div className="w-100 live-support-illustration mt-4">
                <img src="/assets/images/new/unboxing.svg" alt="" />
              </div>
            </div>
          </div>
        ) : (location === "LiveSupport") ? (
          <>
            <div className="live-recent">
              <h3 className="fw500 fz16">
                <span>Open Tickets</span>
                <a href="#" className="trash">
                  <img src="./../assets/images/icons/trash-icon.svg" alt="" />
                </a>
              </h3>
              <ul className="list-unstyled">
                {
                  tickets?.data?.map(ele => (
                    <li>
                      <div onClick={() => window.location.href = "/live-support?ticketId=" + ele.uuid} className="recents cursor-pointer">
                        <div className="recents__top">
                          <div className="left">
                            <div className="primary-box">
                              <img src="./../assets/images/icons/recent-created-icon.svg" alt="" />
                            </div>
                            <span className="fz12 fw500">New Ticket created</span>
                          </div>
                        </div>
                        <p className="fz12 grayText">
                          {ele.topic}
                        </p>
                        <div className="latest">
                          Date - {getDateFormat(ele.created_at)[0]} &nbsp;&nbsp; - &nbsp;&nbsp; {getDateFormat(ele.created_at)[1]}
                        </div>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
            <div className="mt-auto sidebar-right-inner__main">
              <div className="pe-3 py-0 chainery-card chainery-card--gray mb-0">
                <div className="w-100 live-support-illustration mt-4">
                  <img src="../assets/images/animations/grow-project/grow-project.svg" alt="" />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="sidebar-right-inner__main">
            {
              location === "profile" ?
                <></>
                :
                <a href="/profile" className="chainery-card">
                  <div className="title-row">
                    <p className="mb-1">
                      <img src="assets/images/icons/last-visited.svg" alt="" />
                      <span className='text-white'>Last login 03hr:30min ago</span>
                    </p>
                    <h1 className="mb-0 fw600 text-white">My Account</h1>
                    <div className="grayText text-white">
                      your account details
                    </div>
                  </div>
                  <button className="redirect-link chart-icon">
                    <img src="assets/images/icons/personal-profile-chart.svg" alt="Chart icon" />
                  </button>
                </a>
            }

            <a href="new-project" className="cursor-pointer chainery-card">
              <img src="assets/images/create-project.svg" alt="" />
              <div className="title-row cursor-pointer">
                <h2 className="fw600 text-white">Create A New Project</h2>
                <p className="lh1 mb-0 text-white">
                  Create a new project by starting it from scratch.
                </p>
              </div>
              <button className="redirect-link">
                <img src="assets/images/icons/arrow-right.svg" alt="" />
              </button>
            </a>
            <a href="/new-project#projectList" className="cursor-pointer chainery-card">
              <img src="assets/images/manage-product.svg" alt="" />
              <div className="title-row">
                <h2 className="fw600 text-white">Manage your Projects</h2>
                <p className="lh1 mb-0 text-white">
                  Add your own product to start your project
                </p>
              </div>
              <button className="redirect-link">
                <img src="assets/images/icons/arrow-right.svg" alt="" />
              </button>
            </a>
            <div onClick={() => { window.location.href = "/live-support" }} className="cursor-pointer chainery-card chainery-card--gray mb-0">
              <div className="text-center ms-0 title-row">
                <h2 className="fw600">Live Support</h2>
                <p className="lh1 mb-0">24/7 Available at your service</p>
              </div>
              <div className="live-support-illustration cursor-pointer mt-2">
                <img src="./assets/images/new/reading.svg" alt="" />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}