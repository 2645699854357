import React, { useEffect, useState } from 'react'
import RightSidebar from '../RightSidebar'
import Loading from '../Loading';
import useFetch from '../../utils/hooks/useFetch';
import ProjectListWidget from '../ProjectListWidget';
import { useSearchParams } from 'react-router-dom';
import { axiosClientPy, getDateFormat } from '../../utils';
import { toast } from 'react-toastify';
import SearchComponent from '../SearchComponent';

export default function CreateProjectStep1({ }) {
  const [searchParams] = useSearchParams();
  const mSearch = searchParams.get("search");
  const typeProject = searchParams.get("type");
  const { loading, data } = useFetch("/project/project-creation");

  const goOnNextStep = async (data) => {
    if (mSearch && typeProject) {
      const headers = {
        Authorization: "Token " + window.localStorage.getItem("USER_TOKEN")
      }
      try {
        let networksRes = await axiosClientPy.get("/main/blockchain-network", { headers });
        let selectedNetworkID = networksRes?.data?.data;
        selectedNetworkID = selectedNetworkID?.filter(ele => ele.name.toLowerCase() === data.toLowerCase());

        let typeRes = await axiosClientPy.get("/main/contract-type", { headers });
        let selectedType = typeRes?.data?.data;
        selectedType = selectedType?.filter(ele => ele.name.toLowerCase() === mSearch.toLowerCase());

        if (selectedNetworkID?.length > 0) {
          const mData = new FormData();
          mData.append("project_type", typeProject === "PBP" ? "product" : "service");
          mData.append("step_count", 2);
          const res = await axiosClientPy.post("/project/project-creation", mData, { headers });
          if (typeProject === "PBP") {
            await axiosClientPy.patch("/project/project-creation", { project_id: res.data?.data?.uuid, "contract_network": selectedNetworkID[0].uuid, contract_type: selectedType[0].uuid, step_count: 3 }, { headers });
          } else {
            await axiosClientPy.patch("/project/project-creation", { project_id: res.data?.data?.uuid, "contract_network": selectedNetworkID[0].uuid, name: mSearch, step_count: 3 }, { headers });
          }
          window.location.href = ("/new-project?projectID=" + res.data?.data?.uuid);
        } else {
          toast.error("Network Not Found!!!");
        }
      } catch (error) {
        toast.error("Some Error !!!");
      }
    } else {
      window.location.href = ("/new-project?network=" + data);
    }
  }

  return (
    <>
      <Loading loading={loading} />
      {/*------- Middle content -------*/}
      <section className="main-content">
        <div className="main-content-inner">
          <div className="title-top-row">
            <div className="title-top-row__left">
              <h1 className="lh-1 fw600">Create New Project!</h1>
              <p>
                <img src="../assets/images/icons/last-visited.svg" alt="Calendar Icon denoting when was last updated" />
                <span>Last visited {getDateFormat(new Date())[2]} - {getDateFormat(new Date())[3]}</span>
              </p>
            </div>
            <SearchComponent />
          </div>

          {/* Completion Row */}
          <div className="primary-box completion-row completion-row--back">
            <div className="completion-row__left">
              <a href="/dashboard" className="redirect-back">
                <img src="./assets/images/icons/back-arrow.svg" alt="Back arrow" />
              </a>
              <div>
                <h1 className="text-white fw600">Networks</h1>
                <p className="m-0 grayText">
                  Please choose a preferred Blockchain Network
                </p>
                <div className="mb-0">
                  <span className="fw700">Last Updated: </span>
                  <span className="ms-1"> 01-June-2022 - 03hr:55min (EST)</span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 bg-white m-0 projects">
            <ul className="w-100 m-0 list-inline">
              <li>
                <a onClick={(e) => { e.preventDefault(); goOnNextStep("binance"); }} className="cursor-pointer project-card project-card--yellow">
                  <div className="project-card__top">
                    <div className="project-card--left">
                      <div className="logo-wrapper">
                        <img src="./assets/images/binance-full.svg" alt="" />
                      </div>
                      {/* <div className="text">
                        <div className="rounded-circle imgCover trending-up">
                          <svg width={13} height={7} viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.313 1L7.13627 4.95835L4.93796 2.87504L1.64062 6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.67578 1H11.3137V3.5" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                        <span className="ms-1 fw700 greenText">105.23%</span>
                        <h6 className="mb-0 fz12 fw500">than Last Year</h6>
                      </div> */}
                    </div>
                    <div className="project-card--right">
                      <canvas id="sparkLine1" />
                    </div>
                  </div>
                  <div className="w-100 project-card__separator" />
                  <div className="project-card__bottom">
                    <h5 className="fw500 fz12 mb-0">
                      <span>Total Projects:</span>
                      <strong>{data?.filter(ele => ele.contract_network_details?.name === "Binance")?.length}</strong>
                    </h5>
                    <div className="fz12 status">
                      <div className="status__info">
                        <span className="text-white tag">Active</span>
                        <strong className="lh-1">{data?.filter(ele => ele.contract_network_details?.name === "Binance" && ele.project_status !== "verified")?.length}</strong>
                      </div>
                      <div className="status__info">
                        <span className="text-white tag">Inactive</span>
                        <strong className="lh-1">{data?.filter(ele => ele.contract_network_details?.name === "Binance" && ele.project_status === "verified")?.length}</strong>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a onClick={(e) => { e.preventDefault(); goOnNextStep("ethereum"); }} className="cursor-pointer project-card project-card--red">
                  <div className="project-card__top">
                    <div className="project-card--left">
                      <div className="logo-wrapper">
                        <img src="./assets/images/ethereum-full.svg" alt="" />
                      </div>
                      {/* <div className="text">
                        <div className="rounded-circle imgCover trending-up">
                          <svg width={13} height={7} viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.313 1L7.13627 4.95835L4.93796 2.87504L1.64062 6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.67578 1H11.3137V3.5" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                        <span className="ms-1 fw700 greenText">105.23%</span>
                        <h6 className="mb-0 fz12 fw500">than Last Year</h6>
                      </div> */}
                    </div>
                    <div className="project-card--right">
                      <canvas id="sparkLine2" />
                    </div>
                  </div>
                  <div className="w-100 project-card__separator" />
                  <div className="project-card__bottom">
                    <h5 className="fw500 fz12 mb-0">
                      <span >Total Projects:</span>
                      <strong >{data?.filter(ele => ele.contract_network_details?.name === "Ethereum")?.length}</strong>
                    </h5>
                    <div className="fz12 status">
                      <div className="status__info">
                        <span className="text-white tag">Active</span>
                        <strong className="lh-1">{data?.filter(ele => ele.contract_network_details?.name === "Ethereum" && ele.project_status !== "verified")?.length}</strong>
                      </div>
                      <div className="status__info">
                        <span className="text-white tag">Inactive</span>
                        <strong className="lh-1">{data?.filter(ele => ele.contract_network_details?.name === "Ethereum" && ele.project_status === "verified")?.length}</strong>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a onClick={(e) => { e.preventDefault(); goOnNextStep("polygon"); }} className="cursor-pointer project-card project-card--blue">
                  <div className="project-card__top">
                    <div className="project-card--left">
                      <div className="logo-wrapper">
                        <img src="./assets/images/polygon-full.svg" alt="" />
                      </div>
                      {/* <div className="text">
                        <div className="rounded-circle imgCover trending-up">
                          <svg width={13} height={7} viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.313 1L7.13627 4.95835L4.93796 2.87504L1.64062 6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.67578 1H11.3137V3.5" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                        <span className="ms-1 fw700 greenText">105.23%</span>
                        <h6 className="mb-0 fz12 fw500">than Last Year</h6>
                      </div> */}
                    </div>
                    <div className="project-card--right">
                      <canvas id="sparkLine3" />
                    </div>
                  </div>
                  <div className="w-100 project-card__separator" />
                  <div className="project-card__bottom">
                    <h5 className="fw500 fz12 mb-0">
                      <span >Total Projects:</span>
                      <strong >{data?.filter(ele => ele.contract_network_details?.name === "Polygon")?.length}</strong>
                    </h5>
                    <div className="fz12 status">
                      <div className="status__info">
                        <span className="text-white tag">Active</span>
                        <strong className="lh-1">{data?.filter(ele => ele.contract_network_details?.name === "Polygon" && ele.project_status !== "verified")?.length}</strong>
                      </div>
                      <div className="status__info">
                        <span className="text-white tag">Inactive</span>
                        <strong className="lh-1">{data?.filter(ele => ele.contract_network_details?.name === "Polygon" && ele.project_status === "verified")?.length}</strong>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a onClick={(e) => { e.preventDefault(); goOnNextStep("cardano"); }} className="cursor-pointer project-card project-card--green">
                  <div className="project-card__top">
                    <div className="project-card--left">
                      <div className="logo-wrapper">
                        <img src="./assets/images/cardano-full.svg" alt="" />
                      </div>
                      {/* <div className="text">
                        <div className="rounded-circle imgCover trending-down">
                          <svg width={13} height={7} viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.313 1L7.13627 4.95835L4.93796 2.87504L1.64062 6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.67578 1H11.3137V3.5" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                        <span className="ms-1 fw700 redText">105.23%</span>
                        <h6 className="mb-0 fz12 fw500">than Last Year</h6>
                      </div> */}
                    </div>
                    <div className="project-card--right">
                      <canvas id="sparkLine4" />
                    </div>
                  </div>
                  <div className="w-100 project-card__separator" />
                  <div className="project-card__bottom">
                    <h5 className="fw500 fz12 mb-0">
                      <span >Total Projects:</span>
                      <strong >{data?.filter(ele => ele.contract_network_details?.name === "Cardano")?.length}</strong>
                    </h5>
                    <div className="fz12 status">
                      <div className="status__info">
                        <span className="text-white tag">Active</span>
                        <strong className="lh-1">{data?.filter(ele => ele.contract_network_details?.name === "Cardano" && ele.project_status !== "verified")?.length}</strong>
                      </div>
                      <div className="status__info">
                        <span className="text-white tag">Inactive</span>
                        <strong className="lh-1">{data?.filter(ele => ele.contract_network_details?.name === "Cardano" && ele.project_status === "verified")?.length}</strong>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          {/* =================================== */}
          {/*-------- Project List widget --------*/}
          <ProjectListWidget data={data} />
          {/* =================================== */}
        </div>
      </section >
      {/* ====================================== */}
      {/*------- Right Sidebar  -------*/}
      <RightSidebar location="CreateProject" />
      {/* ============================ */}
    </>
  )
}