import React, { useState } from 'react'
import TopRightProfile from '../../TopRightProfile';
import { useSearchParams } from 'react-router-dom';
import { axiosClientPy } from '../../../utils';
import { toast } from 'react-toastify';

export default function CreateProjectERC20Step2({ projectData, setStep }) {
  const [tSymbol, setTSymbol] = useState(projectData?.token_symbol ? projectData.token_symbol : "");

  const onSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      Authorization: "Token " + window.localStorage.getItem("USER_TOKEN")
    }
    try {
      const mData = new FormData();
      mData.append("project_id", projectData?.uuid);
      mData.append("token_symbol", tSymbol);
      mData.append("step_count", 5);
      const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
      setStep(3);
    } catch (error) {
      toast.error("Internal Server Error!!!")
    }
  }

  return (
    <section className="pe-0 main-content stepper-content">
      <div className="main-content-inner">
        <TopRightProfile />
        <div className="stepperForm">
          <div className="leftStepper">
            {/*----- Completion stats row -----*/}
            <div className="primary-box completion-row completion-row--back">
              <div className="completion-row__left">
                <button onClick={() => { setStep(1); }} className="redirect-back">
                  <img src="./assets/images/icons/back-arrow.svg" alt="Back arrow" />
                </button>
                <div >
                  <h1 className="fw600 text-white">ERC - 20</h1>
                  <p className="m-0 grayText">Most basic token standard, used to create interchangeable
                    tokens.</p>
                </div>
              </div>
              <div className="completion-row__right">
                <img className="#" src="./assets/images/fungible-token.svg" alt="" />
              </div>
            </div>
            <div className="profile-edit erc721">
              <div className="left_stepperwrapper ">
                <h3 className="fw700 grayText--lighter fz14">Step 2. <strong className="darkText fw700 fz14">Token Symbol</strong></h3>
                <p className="grayText--lighter fz12">
                  Choosing a symbol for your token is more than just a formality – it's an opportunity to create a visual identity that speaks volumes. Create it now.
                </p>
                <div className="form-box form-box--violet profile-edit__left stepper-form-details">
                  <form onSubmit={onSubmit} className="form">
                    <div className="mb-5 form-section">
                      <p className="grayText fz12">Choosing a symbol for your token is more than just a formality – it's an opportunity to create a visual identity that speaks volumes. Create it now.</p>
                    </div>
                    <div className="top">
                      <div className="form-group">
                        <div className="form-group__inner">
                          <label htmlFor className="label">
                            <div className="primary-box label__icon">
                              <img src="./assets/images/icons/nft-label.svg" alt="User icon" />
                            </div>
                          </label>
                          <input type="text" value={tSymbol} onChange={(e) => { setTSymbol(e.target.value) }} className="rounded-0 border-0 bg-transparent p-0 form-control italic-placeholder" placeholder="Symbol" />
                        </div>
                      </div>
                    </div>
                    <div className="btnGroup">
                      <button role="button" className="btn rounded-0 px-2 text-nowrap btn--transparent-outlined">
                        Cancel
                      </button>
                      <button role="submit" className="btn rounded-0 primary-box btn--primary">
                        Next
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="right_stepperwrapper">
                <h3 className="fw700 fz14">Next Step:  Pre-Mint</h3>
                <p className="grayText--lighter fz12">
                  you can release some tokens before they are released to the public.
                </p>
                <div className="text-center profile-edit__right">
                  <img className="w-100" src="./assets/images/animations/stepper1/stepper1.svg" alt="" />
                </div>
              </div>
            </div>
          </div> {/* leftStepper */}
          <div className="rightStepper">
            <div className="stepper_Steps">
              <div className="stepper-head">
                <a href="#" className=" stepper-card-head">
                  <div className="d-flex justify-content-between title-row stepper_Links">
                    <button className="stepper-redirect-link">
                      <img className="rounded mx-auto d-block" style={{ transform: 'rotate(180deg)' }} src="assets/images/icons/arrow-right.svg" alt="" />
                    </button>
                    <h2 className="fw700">Smart Contract Steps</h2>
                    <button className="stepper-redirect-link">
                      <img className="rounded mx-auto d-block" src="assets/images/icons/arrow-right.svg" alt="" />
                    </button>
                  </div>
                </a>
              </div>
              <hr className="mt-0" />
              <div className="stepper-counts">
                <div className="outlined-card">
                  <a role="button" className="stepper-card stepper-card--pink">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>1.</h2>
                      </div>
                      <h2 className="fw500">Token Name</h2>
                    </div>
                  </a>
                </div>
                <div className="outlined-card">
                  <a role="button" className="stepper-card stepper-card--orange">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>2.</h2>
                      </div>
                      <h2 className="fw500">Token Symbol</h2>
                    </div>
                  </a>
                </div>
                <div className="outlined-card outlined-card--inactive">
                  <a role="button" className="stepper-card stepper-card--yellow">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>3.</h2>
                      </div>
                      <h2 className="fw500">Pre Mint</h2>
                    </div>
                  </a>
                </div>
                <div className="outlined-card outlined-card--inactive">
                  <a role="button" className="stepper-card stepper-card--blue">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>4.</h2>
                      </div>
                      <h2 className="fw500">Functions</h2>
                    </div>
                  </a>
                </div>
                <div className="outlined-card outlined-card--inactive">
                  <a role="button" className="stepper-card stepper-card--blue">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>5.</h2>
                      </div>
                      <h2 className="fw500">Overview</h2>
                    </div>
                  </a>
                </div>
                <div className="outlined-card outlined-card--inactive">
                  <a role="button" className="stepper-card stepper-card--pink">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>6.</h2>
                      </div>
                      <h2 className="fw500">Deploy ERC-20</h2>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>{/* rightStepper */}
        </div>
      </div>
    </section>
  )
}