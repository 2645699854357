import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import LeftSidebar from '../components/LeftSidebar';
import TopRightProfile from '../components/TopRightProfile';
import Loading from '../components/Loading';
import useFetch from '../utils/hooks/useFetch';
import { axiosClientPy } from '../utils';

export default function Exams() {
  const { tutorialId } = useParams();
  const [mData, setMData] = useState(null);
  const [isStart, setIsStart] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const { data, loading, reFetch: reFetchCourse } = useFetch("/courses/courses?course_id=" + tutorialId);
  const [selected, setSelected] = useState('');
  const headers = {
    Authorization: "Token " + window.localStorage.getItem("USER_TOKEN")
  }

  const reFetch = (isRestart = false) => {
    let url = `/courses/course-questions?course_id=${tutorialId}`;
    if (isRestart) {
      url += "&is_restart=True";
    }
    axiosClientPy.get(url, { headers }).than(response => {
      setMData(response.data);
    }).catch(err => {
      if (err?.response?.data?.quiz_ended) {
        setIsResult(true);
        reFetchCourse();
      }
      console.log(err.response.data);
    })
  }

  useEffect(() => {
    reFetch();
  }, [])

  const submitQuestion = (question_id) => {
    if (!selected) return;
    const mData = {
      question_id: question_id,
      selected_option: selected,
    }
    axiosClientPy.post("/courses/submit-question-response", mData, { headers }).than(response => {
      setSelected("");
      reFetch();
    }).catch(error => {
      console.log(error)
    });
  }

  return (
    <>
      <Loading loading={loading} />
      <div className="mob-menu-btn"><span /><span /><span /></div>
      <section className='page-wrapper'>
        <LeftSidebar active={"tutorials"} />

        <section className="pe-0 main-content">
          <div className="pt-0 main-content-inner">
            <TopRightProfile />
            {/*----- Completion stats row -----*/}
            <div className="d-none d-lg-flex primary-box completion-row completion-row--back">
              <div className="completion-row__left">
                <a href="/tutorials" className="redirect-back">
                  <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
                </a>
                <div>
                  <h1 className="fw600 text-white">Exams</h1>
                  <p className="m-0 grayText">
                    Watch &amp; learn from categorzed courses
                  </p>
                  <div className="mb-0">
                    <span className="fw700">Last Updated: </span>
                    <span className="ms-1"> 01-June-2022 - 03hr:55min (EST)</span>
                  </div>
                </div>
              </div>
            </div>

            {
              isResult ? <>
                <div className='exams-start-section'>
                  <div className='exams-start-section-inner'>
                    <h2>{data?.course_name}</h2>
                    <p>Result : <span className={`badge text-bg-${data?.is_passed ? "success" : "danger"}`}>{data?.is_passed ? "Pass" : "Fail"}</span></p>
                    <p className='mb-1'>Total Questions : {data?.total_questions_count}</p>
                    <p>Correct Answers : {data?.correct_answers_count}</p>
                    <hr />
                    {
                      data?.is_passed ?
                        <a target='_blank' href={data?.certificate} className='btn mt-2 btn-dd--primary'>
                          Download Certificate
                        </a>
                        :
                        <>
                          <button onClick={() => { reFetch(true); setIsStart(true); setIsResult(false); }} className='btn mt-2 btn-dd--primary'>
                            Re-Start
                          </button>
                        </>
                    }
                  </div>
                </div>
              </> :
                isStart ?
                  <div className='exams-start-section'>
                    <div className='exams-start-section-inner'>
                      {
                        mData?.data?.length > 0 && <>
                          <div className='info'>
                            <span className="steps">{mData?.current_page}/{data?.total_questions_count}</span>
                            <span className="question">{mData?.data[0].question}</span>
                          </div>

                          <div className='mt-3'>
                            <input type="radio" id="value-1" name="value-radio" value="option1" checked={selected == "option1"} onChange={() => { setSelected("option1") }} />
                            <label htmlFor="value-1">{mData?.data[0]?.option1}</label>

                            <input type="radio" id="value-2" name="value-radio" value="option2" checked={selected == "option2"} onChange={() => { setSelected("option2") }} />
                            <label htmlFor="value-2">{mData?.data[0]?.option2}</label>

                            <input type="radio" id="value-3" name="value-radio" value="option3" checked={selected == "option3"} onChange={() => { setSelected("option3") }} />
                            <label htmlFor="value-3">{mData?.data[0]?.option3}</label>

                            <input type="radio" id="value-4" name="value-radio" value="option4" checked={selected == "option4"} onChange={() => { setSelected("option4") }} />
                            <label htmlFor="value-4">{mData?.data[0]?.option4}</label>
                          </div>

                          <button type='btn' onClick={() => submitQuestion(mData?.data[0].uuid)} className='btn mt-3 btn-dd--primary'>
                            Save & Next
                          </button>
                        </>
                      }

                    </div>
                  </div>
                  :
                  <div className='exams-start-section'>
                    <div className='exams-start-section-inner'>
                      <h2>{data?.course_name}</h2>
                      <p>Start Your Exam By Clicking Below</p>
                      <p>Total Questions : {data?.total_questions_count}</p>
                      <hr />
                      <button onClick={() => { if (data?.total_questions_count > 0) setIsStart(true); }} className='btn mt-3 btn-dd--primary'>
                        Start
                      </button>
                    </div>
                  </div>
            }

          </div>
        </section>

      </section >
    </>
  )
}
