// import React, { useEffect, useState } from 'react'

// const fixedVal = 25;
// const mQuery = (data, filter) => `// projectStatsLineChart
//   if (document.getElementById("projectStatsLineChart")) {
//     var ctx = document.getElementById("projectStatsLineChart").getContext("2d");
//     const mode = "index";

//     var gradient1 = ctx.createLinearGradient(0, 0, 0, 280);
//     gradient1.addColorStop(0, "rgba(241, 233, 243, 0.50)");
//     gradient1.addColorStop(1, "rgba(241, 233, 243, 0)");

//     var gradient2 = ctx.createLinearGradient(0, 0, 0, 280);
//     gradient2.addColorStop(0, "rgba(40, 40, 40, 0.30)");
//     gradient2.addColorStop(1, "rgba(40, 40, 40, 0)");

//     var myChart = new Chart(ctx, {
//       type: "line",
//       data: {
//         labels: [${filter === "Year" ? Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - 1 + i) : Array.from({ length: 12 }, (_, i) => (i + 1))}],
//         datasets: [
//           {
//             label: "",
//             fill: "origin",
//             borderColor: "#282828",
//             backgroundColor: gradient2,
//             borderWidth: 5,
//             data: [0, ${data?.filter(ele => ele.contract_address && ele.project_status !== "verified").length * fixedVal}, 0, 0, 0, 0, 0, 0, 0, 0],
//             pointBorderColor: "rgba(0, 0, 0, 0)",
//             pointBackgroundColor: "rgba(0, 0, 0, 0)",
//             pointHoverBackgroundColor: "#282828",
//             pointHoverBorderColor: "#ffffff",
//             pointHoverBorderWidth: 8,
//             pointHoverRadius: 8,
//             order: 1,
//             fill: "origin",
//           },
//           {
//             label: "",
//             fill: "origin",
//             borderColor: "#EFDFEF",
//             backgroundColor: gradient1,
//             borderWidth: 5,
//             data: [0, ${data?.filter(ele => ele.project_status === "in_progress").length * fixedVal}, 0, 0, 0, 0, 0, 0, 0, 0],
//             pointBorderColor: "rgba(0, 0, 0, 0)",
//             pointBackgroundColor: "rgba(0, 0, 0, 0)",
//             pointHoverBackgroundColor: "#EFDFEF",
//             pointHoverBorderColor: "#ffffff",
//             pointHoverBorderWidth: 8,
//             pointHoverRadius: 8,
//             order: 2,
//           },
//           {
//             label: "",
//             fill: "origin",
//             borderColor: "#C6EAFF",
//             backgroundColor: "transparent",
//             borderWidth: 5,
//             data: [0, ${data?.filter(ele => ele.project_status === "verified").length * fixedVal}, 0, 0, 0, 0, 0, 0, 0, 0],
//             pointBorderColor: "rgba(0, 0, 0, 0)",
//             pointBackgroundColor: "rgba(0, 0, 0, 0)",
//             pointHoverBackgroundColor: "#C6EAFF",
//             pointHoverBorderColor: "#ffffff",
//             pointHoverBorderWidth: 8,
//             pointHoverRadius: 8,
//             order: 2,
//           },
//         ],
//       },
//       options: {
//         responsive: true,
//         maintainAspectRatio: false,
//         tooltip: {
//           padding: 8,
//           backgroundColor: "rgba(0, 0, 0, 0.48)",
//           titleFont: {
//             weight: "bold",
//           },
//         },
//         hover: {
//           mode: mode,
//           intersect: false,
//         },
//         scales: {
//           x: {
//             beginAtZero: true,
//             grid: {
//               color: "#f7f8fe",
//             },
//             ticks: {
//               color: "#8F92A1",
//             },
//             border: {
//               color: "#f7f8fe",
//             },
//           },

//           y: {
//             beginAtZero: true,
//             labels: [100, 200, 300, 400, 500],
//             grid: {
//               display: false,
//               drawOnChartArea: false,
//               drawTicks: false,
//             },
//             ticks: {
//               minRotation: 90,
//               padding: 10,
//               color: "#8F92A1",
//             },
//             border: {
//               color: "#f7f8fe",
//             },
//           },
//         },
//         elements: {
//           line: {
//             tension: 0.45,
//           },
//         },
//         layout: {
//           padding: {
//             top: 15,
//             bottom: 15,
//           },
//         },
//         plugins: {
//           legend: {
//             display: false,
//           },
//         },
//       },
//     });
//   }

//   // serviceStatsLineChart
//   if (document.getElementById("serviceStatsLineChart")) {
//     var ctx = document.getElementById("serviceStatsLineChart").getContext("2d");
//     const mode = "index";

//     var gradient1 = ctx.createLinearGradient(0, 0, 0, 280);
//     gradient1.addColorStop(0, "rgba(241, 233, 243, 0.50)");
//     gradient1.addColorStop(1, "rgba(241, 233, 243, 0)");

//     var gradient2 = ctx.createLinearGradient(0, 0, 0, 280);
//     gradient2.addColorStop(0, "rgba(40, 40, 40, 0.30)");
//     gradient2.addColorStop(1, "rgba(40, 40, 40, 0)");

//     var myChart = new Chart(ctx, {
//       type: "line",
//       data: {
//         labels: [
//           "2000",
//           "2001",
//           "2002",
//           "2003",
//           "2004",
//           "2005",
//           "2006",
//           "2007",
//           "2008",
//           "2009",
//         ],
//         datasets: [
//           {
//             label: "",
//             fill: "origin",
//             borderColor: "#282828",
//             backgroundColor: gradient2,
//             borderWidth: 5,
//             data: [40, 100, 50, 85, 70, 120, 150, 130, 160, 200],
//             pointBorderColor: "rgba(0, 0, 0, 0)",
//             pointBackgroundColor: "rgba(0, 0, 0, 0)",
//             pointHoverBackgroundColor: "#282828",
//             pointHoverBorderColor: "#ffffff",
//             pointHoverBorderWidth: 8,
//             pointHoverRadius: 8,
//             order: 1,
//             fill: "origin",
//           },
//           {
//             label: "",
//             fill: "origin",
//             borderColor: "#EFDFEF",
//             backgroundColor: gradient1,
//             borderWidth: 5,
//             data: [0, 120, 80, 125, 150, 100, 180, 100, 220, 100],
//             pointBorderColor: "rgba(0, 0, 0, 0)",
//             pointBackgroundColor: "rgba(0, 0, 0, 0)",
//             pointHoverBackgroundColor: "#EFDFEF",
//             pointHoverBorderColor: "#ffffff",
//             pointHoverBorderWidth: 8,
//             pointHoverRadius: 8,
//             order: 2,
//           }, 
//         ],
//       },
//       options: {
//         responsive: true,
//         maintainAspectRatio: false,
//         tooltip: {
//           padding: 8,
//           backgroundColor: "rgba(0, 0, 0, 0.48)",
//           titleFont: {
//             weight: "bold",
//           },
//         },
//         hover: {
//           mode: mode,
//           intersect: false,
//         },
//         scales: {
//           x: {
//             beginAtZero: true,
//             grid: {
//               color: "#f7f8fe",
//             },
//             ticks: {
//               color: "#8F92A1",
//             },
//             border: {
//               color: "#f7f8fe",
//             },
//           },

//           y: {
//             beginAtZero: true,
//             labels: [100, 200, 300, 400, 500],
//             grid: {
//               display: false,
//               drawOnChartArea: false,
//               drawTicks: false,
//             },
//             ticks: {
//               minRotation: 90,
//               padding: 10,
//               color: "#8F92A1",
//             },
//             border: {
//               color: "#f7f8fe",
//             },
//           },
//         },
//         elements: {
//           line: {
//             tension: 0.45,
//           },
//         },
//         layout: {
//           padding: {
//             top: 15,
//             bottom: 15,
//           },
//         },
//         plugins: {
//           legend: {
//             display: false,
//           },
//         },
//       },
//     });
//   }
//   // Toggle btw services' & projects' LINE-CHARTS on dropdown-item selection //
// $(".dd-item-line").on("click", function () {
//   // Remove the "dd-active" class from all dropdown items
//   $(".dd-item-line").removeClass("dd-active");
//   $(".dd-item-custom").removeClass("dd-active");

//   // Get the selected class and add the "dd-active" class to the clicked item
//   var selectedClass = $(this).data("class");

//   $(this).addClass("dd-active");

//   // Toggle the selected class in div.toggle-line-chart
//   $("#toggle-line-chart")
//     .removeClass()
//     .addClass("toggle-line-chart")
//     .addClass(selectedClass);

//   $("#toggle-grid")
//     .removeClass()
//     .addClass("toggle-grid")
//     .addClass(selectedClass);

//     if (selectedClass == "product-grid") {
//       $("#js--deploy").html("Add new product");
//       $("#dropdownMenuButton").html("Products");
//       $("#ddLineMenuBtn").html("Products");
//       $(".dd-item-custom").first().addClass("dd-active")
//     } else {
//       $("#js--deploy").html("Create new services");
//       $("#dropdownMenuButton").html("Services");
//       $("#ddLineMenuBtn").html("Services");
//       $(".dd-item-custom").last().addClass("dd-active")
//     }
// });
//   `;

// export default function MultiLineChart({ data, network }) {
//   const [filter, setFilter] = useState('Year')
//   const [dataset, setDataset] = useState({
//     deployed: 0,
//     inprogress: 0,
//     verified: 0
//   })

//   useEffect(() => {
//     if (data) {
//       setDataset({
//         deployed: data?.filter(ele => ele.contract_address && ele.project_status !== "verified").length * fixedVal,
//         inprogress: data?.filter(ele => ele.project_status === "in_progress").length * fixedVal,
//         verified: data?.filter(ele => ele.project_status === "verified").length * fixedVal
//       })
//       if (document.getElementById("projectStatsLineChartScript1")) {
//         document.body.removeChild(document.getElementById("projectStatsLineChartScript1"))
//         const script = document.createElement("script");
//         script.innerHTML = mQuery(data, filter);
//         script.id = "projectStatsLineChartScript1";
//         document.body.append(script);
//       } else {
//         const script = document.createElement("script");
//         script.innerHTML = mQuery(data, filter);
//         script.id = "projectStatsLineChartScript1";
//         document.body.append(script);
//       }
//     }
//   }, [data, filter]);

//   return (
//     <div className="w-100 bg-white line-chart-box">
//       <div className="top-row">
//         <div className="title-row">
//           <h2 className="mb-0 lh1-5 fw500">Project Stats</h2>
//           <div className="mb-0">
//             <span>Avg Project rate - </span>
//             <div className="rounded-circle imgCover trending-up">
//               <svg width={13} height={7} viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
//                 <path d="M11.313 1L7.13627 4.95835L4.93796 2.87504L1.64062 6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
//                 <path d="M8.67578 1H11.3137V3.5" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
//               </svg>
//             </div>
//             <span className="ms-1 fw700 greenText">105.23%</span>
//           </div>
//         </div>
//         <div className="top-row__options">
//           <label htmlFor className="fw500">Project Type:</label>
//           <div className="dropdown">
//             <button className="btn border-0 dropdown-toggle btn-dd btn-dd--primary" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="ddLineMenuBtn">
//               Projects
//             </button>
//             <ul className="dropdown-menu">
//               <li>
//                 <a className="dropdown-item dd-item-line dd-active" role="button" data-class="product-grid">Products</a>
//               </li>
//               <li>
//                 <a className="dropdown-item dd-item-line" role="button" data-class="service-grid">Services</a>
//               </li>
//             </ul>
//           </div>
//           <div className="dropdown">
//             <button className="btn border-0 dropdown-toggle btn-dd btn-dd--primary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
//               {filter}
//             </button>
//             <ul className="dropdown-menu">
//               <li>
//                 <a className="dropdown-item" role='button' onClick={() => setFilter("Month")}>Month</a>
//               </li>
//               <li>
//                 <a className="dropdown-item" role='button' onClick={() => setFilter("Year")}>Year</a>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//       <div id="toggle-line-chart" className="toggle-line-chart product-grid">
//         <div className="line-wrapper line-wrapper-product">
//           <ul className="list-inline mb-0 legends">
//             <li>
//               <div className="pair">
//                 <div className="pair__color pair__color--primary" />
//                 <div className="pair__text">
//                   Deployed <b className="fw700">{dataset.deployed}</b>
//                 </div>
//               </div>
//             </li>
//             <li>
//               <div className="pair">
//                 <div className="pair__color pair__color--pink" />
//                 <div className="pair__text">
//                   Inprogress <b className="fw700">{dataset.inprogress}</b>
//                 </div>
//               </div>
//             </li>
//             <li>
//               <div className="pair">
//                 <div className="pair__color pair__color--blue" />
//                 <div className="pair__text">
//                   Verified <b className="fw700">{dataset.verified}</b>
//                 </div>
//               </div>
//             </li>
//           </ul>
//           <div className="chart-container">
//             <canvas id="projectStatsLineChart" style={{ height: '325px' }} />
//           </div>
//         </div>
//         <div className="line-wrapper line-wrapper-service">
//           <ul className="list-inline mb-0 legends">
//             <li>
//               <div className="pair">
//                 <div className="pair__color pair__color--primary" />
//                 <div className="pair__text">
//                   Responded <b className="fw700">200k</b>
//                 </div>
//               </div>
//             </li>
//             <li>
//               <div className="pair">
//                 <div className="pair__color pair__color--pink" />
//                 <div className="pair__text">
//                   Waiting <b className="fw700">200k</b>
//                 </div>
//               </div>
//             </li>
//           </ul>
//           <div className="chart-container">
//             <canvas id="serviceStatsLineChart" style={{ height: '325px' }} />
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

import React from 'react'
import { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useEffect } from 'react';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const fixedVal = 25;
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const options = {
  responsive: true,
  maintainAspectRatio: false,
  tooltip: {
    padding: 8,
    backgroundColor: "rgba(0, 0, 0, 0.48)",
    titleFont: {
      weight: "bold",
    },
  },
  hover: {
    mode: "index",
    intersect: false,
  },
  plugins: {
    legend: false,
    title: {
      display: false
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        color: "#f7f8fe",
      },
      ticks: {
        color: "#8F92A1",
      },
      border: {
        color: "#f7f8fe",
      },
    },

    y: {
      beginAtZero: true,
      labels: [100, 200, 300, 400, 500],
      grid: {
        display: false,
        drawOnChartArea: false,
        drawTicks: false,
      },
      ticks: {
        minRotation: 90,
        padding: 10,
        color: "#8F92A1",
      },
      border: {
        color: "#f7f8fe",
      },
    },
  },
  elements: {
    line: {
      tension: 0.45,
    },
  },
  layout: {
    padding: {
      top: 15,
      bottom: 15,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export default function MultiLineChart({ data, network }) {
  const [filter, setFilter] = useState("Year")
  const [labels, setLabels] = useState(Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - 1 + i))
  const [dataset, setDataset] = useState({
    deployed: [],
    inprogress: [],
    verified: [],
    responded: [],
    waiting: []
  })

  const chartData = {
    labels,
    datasets: [
      {
        data: dataset.deployed,
        label: 'Deployed',
        fill: "origin",
        borderColor: '#282828',
        borderWidth: 5,
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointBackgroundColor: "rgba(0, 0, 0, 0)",
        pointHoverBackgroundColor: "#282828",
        pointHoverBorderColor: "#ffffff",
        pointHoverBorderWidth: 8,
        pointHoverRadius: 8,
        order: 1,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 280);
          gradient.addColorStop(0, "rgba(40, 40, 40, 0.30)");
          gradient.addColorStop(1, "rgba(40, 40, 40, 0)");
          return gradient;
        },
      },
      {
        data: dataset.inprogress,
        label: "Inprogress",
        fill: "origin",
        borderColor: "#e24c4d",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 280);
          gradient.addColorStop(0, "rgba(241, 233, 243, 0.50)");
          gradient.addColorStop(1, "rgba(241, 233, 243, 0)");
          return gradient;
        },
        borderWidth: 5,
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointBackgroundColor: "rgba(0, 0, 0, 0)",
        pointHoverBackgroundColor: "#EFDFEF",
        pointHoverBorderColor: "#ffffff",
        pointHoverBorderWidth: 8,
        pointHoverRadius: 8,
        order: 2,
      },
      {
        data: dataset.verified,
        label: "Verified",
        fill: "origin",
        borderColor: "#C6EAFF",
        backgroundColor: "transparent",
        borderWidth: 5,
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointBackgroundColor: "rgba(0, 0, 0, 0)",
        pointHoverBackgroundColor: "#C6EAFF",
        pointHoverBorderColor: "#ffffff",
        pointHoverBorderWidth: 8,
        pointHoverRadius: 8,
        order: 2,
      },
    ],
  };

  const chartDataService = {
    labels,
    datasets: [
      {
        label: "Responded",
        fill: "origin",
        borderColor: "#282828",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient2 = ctx.createLinearGradient(0, 0, 0, 280);
          gradient2.addColorStop(0, "rgba(40, 40, 40, 0.30)");
          gradient2.addColorStop(1, "rgba(40, 40, 40, 0)");
          return gradient2;
        },
        borderWidth: 5,
        data: dataset.responded,
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointBackgroundColor: "rgba(0, 0, 0, 0)",
        pointHoverBackgroundColor: "#282828",
        pointHoverBorderColor: "#ffffff",
        pointHoverBorderWidth: 8,
        pointHoverRadius: 8,
        order: 1,
        fill: "origin",
      },
      {
        label: "Waiting",
        fill: "origin",
        borderColor: "#EFDFEF",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient1 = ctx.createLinearGradient(0, 0, 0, 280);
          gradient1.addColorStop(0, "rgba(241, 233, 243, 0.50)");
          gradient1.addColorStop(1, "rgba(241, 233, 243, 0)");
          return gradient1;
        },
        borderWidth: 5,
        data: dataset.waiting,
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointBackgroundColor: "rgba(0, 0, 0, 0)",
        pointHoverBackgroundColor: "#EFDFEF",
        pointHoverBorderColor: "#ffffff",
        pointHoverBorderWidth: 8,
        pointHoverRadius: 8,
        order: 2,
      },
    ],
  }

  useEffect(() => {
    if (data) {
      if (filter == "Year") {
        setLabels(Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - 1 + i));
        setDataset({
          deployed: [0, data?.filter(ele => ele.contract_address && ele.project_status !== "verified").length * fixedVal, 0, 0, 0, 0, 0, 0, 0, 0],
          inprogress: [0, data?.filter(ele => ele.project_status === "in_progress").length * fixedVal, 0, 0, 0, 0, 0, 0, 0, 0],
          verified: [0, data?.filter(ele => ele.project_status === "verified").length * fixedVal, 0, 0, 0, 0, 0, 0, 0, 0],
          responded: [0, data?.filter(ele => ele.project_type === "service" && ele.project_status !== "in_progress").length * fixedVal, 0, 0, 0, 0, 0, 0, 0, 0],
          waiting: [0, data?.filter(ele => ele.project_type === "service" && ele.project_status === "in_progress").length * fixedVal, 0, 0, 0, 0, 0, 0, 0, 0]
        })
      } else {
        setLabels(Array.from({ length: 12 }, (_, i) => (months[i])));
        setDataset({
          deployed: [
            data.filter(ele => ele.contract_address && ele.project_status !== "verified" && new Date(ele.created_at).getMonth() === 0).length * fixedVal,
            data.filter(ele => ele.contract_address && ele.project_status !== "verified" && new Date(ele.created_at).getMonth() === 1).length * fixedVal,
            data.filter(ele => ele.contract_address && ele.project_status !== "verified" && new Date(ele.created_at).getMonth() === 2).length * fixedVal,
            data.filter(ele => ele.contract_address && ele.project_status !== "verified" && new Date(ele.created_at).getMonth() === 3).length * fixedVal,
            data.filter(ele => ele.contract_address && ele.project_status !== "verified" && new Date(ele.created_at).getMonth() === 4).length * fixedVal,
            data.filter(ele => ele.contract_address && ele.project_status !== "verified" && new Date(ele.created_at).getMonth() === 5).length * fixedVal,
            data.filter(ele => ele.contract_address && ele.project_status !== "verified" && new Date(ele.created_at).getMonth() === 6).length * fixedVal,
            data.filter(ele => ele.contract_address && ele.project_status !== "verified" && new Date(ele.created_at).getMonth() === 7).length * fixedVal,
            data.filter(ele => ele.contract_address && ele.project_status !== "verified" && new Date(ele.created_at).getMonth() === 8).length * fixedVal,
            data.filter(ele => ele.contract_address && ele.project_status !== "verified" && new Date(ele.created_at).getMonth() === 9).length * fixedVal,
            data.filter(ele => ele.contract_address && ele.project_status !== "verified" && new Date(ele.created_at).getMonth() === 10).length * fixedVal,
            data.filter(ele => ele.contract_address && ele.project_status !== "verified" && new Date(ele.created_at).getMonth() === 11).length * fixedVal],
          inprogress: [
            data?.filter(ele => ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 0).length * fixedVal,
            data?.filter(ele => ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 1).length * fixedVal,
            data?.filter(ele => ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 2).length * fixedVal,
            data?.filter(ele => ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 3).length * fixedVal,
            data?.filter(ele => ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 4).length * fixedVal,
            data?.filter(ele => ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 5).length * fixedVal,
            data?.filter(ele => ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 6).length * fixedVal,
            data?.filter(ele => ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 7).length * fixedVal,
            data?.filter(ele => ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 8).length * fixedVal,
            data?.filter(ele => ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 9).length * fixedVal,
            data?.filter(ele => ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 10).length * fixedVal,
            data?.filter(ele => ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 11).length * fixedVal],
          verified: [
            data?.filter(ele => ele.project_status === "verified" && new Date(ele.created_at).getMonth() === 0).length * fixedVal,
            data?.filter(ele => ele.project_status === "verified" && new Date(ele.created_at).getMonth() === 1).length * fixedVal,
            data?.filter(ele => ele.project_status === "verified" && new Date(ele.created_at).getMonth() === 2).length * fixedVal,
            data?.filter(ele => ele.project_status === "verified" && new Date(ele.created_at).getMonth() === 3).length * fixedVal,
            data?.filter(ele => ele.project_status === "verified" && new Date(ele.created_at).getMonth() === 4).length * fixedVal,
            data?.filter(ele => ele.project_status === "verified" && new Date(ele.created_at).getMonth() === 5).length * fixedVal,
            data?.filter(ele => ele.project_status === "verified" && new Date(ele.created_at).getMonth() === 6).length * fixedVal,
            data?.filter(ele => ele.project_status === "verified" && new Date(ele.created_at).getMonth() === 7).length * fixedVal,
            data?.filter(ele => ele.project_status === "verified" && new Date(ele.created_at).getMonth() === 8).length * fixedVal,
            data?.filter(ele => ele.project_status === "verified" && new Date(ele.created_at).getMonth() === 9).length * fixedVal,
            data?.filter(ele => ele.project_status === "verified" && new Date(ele.created_at).getMonth() === 10).length * fixedVal,
            data?.filter(ele => ele.project_status === "verified" && new Date(ele.created_at).getMonth() === 11).length * fixedVal],
          responded: [
            data?.filter(ele => ele.project_type === "service" && ele.project_status !== "in_progress" && new Date(ele.created_at).getMonth() === 0).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status !== "in_progress" && new Date(ele.created_at).getMonth() === 1).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status !== "in_progress" && new Date(ele.created_at).getMonth() === 2).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status !== "in_progress" && new Date(ele.created_at).getMonth() === 3).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status !== "in_progress" && new Date(ele.created_at).getMonth() === 4).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status !== "in_progress" && new Date(ele.created_at).getMonth() === 5).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status !== "in_progress" && new Date(ele.created_at).getMonth() === 6).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status !== "in_progress" && new Date(ele.created_at).getMonth() === 7).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status !== "in_progress" && new Date(ele.created_at).getMonth() === 8).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status !== "in_progress" && new Date(ele.created_at).getMonth() === 9).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status !== "in_progress" && new Date(ele.created_at).getMonth() === 10).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status !== "in_progress" && new Date(ele.created_at).getMonth() === 11).length * fixedVal],
          waiting: [
            data?.filter(ele => ele.project_type === "service" && ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 0).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 1).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 2).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 3).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 4).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 5).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 6).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 7).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 8).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 9).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 10).length * fixedVal,
            data?.filter(ele => ele.project_type === "service" && ele.project_status === "in_progress" && new Date(ele.created_at).getMonth() === 11).length * fixedVal]
        })
      }
    }
  }, [filter, data])

  return (
    <div className="w-100 bg-white line-chart-box">
      <div className="top-row">
        <div className="title-row">
          <h2 className="mb-0 lh1-5 fw500">Project Stats</h2>
          {/* <div className="mb-0">
            <span>Avg Project rate - </span>
            <div className="rounded-circle imgCover trending-up">
              <svg width={13} height={7} viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.313 1L7.13627 4.95835L4.93796 2.87504L1.64062 6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.67578 1H11.3137V3.5" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <span className="ms-1 fw700 greenText">105.23%</span>
          </div> */}
        </div>
        <div className="top-row__options">
          <label htmlFor className="fw500">Project Type:</label>
          <div className="dropdown">
            <button className="btn border-0 dropdown-toggle btn-dd btn-dd--primary" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="ddLineMenuBtn">
              Products
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item dd-item-line dd-active" href="javascript:void(0)" data-class="product-grid">Products</a>
              </li>
              <li>
                <a className="dropdown-item dd-item-line" href="javascript:void(0)" data-class="service-grid">Services</a>
              </li>
            </ul>
          </div>
          <div className="dropdown">
            <button className="btn border-0 dropdown-toggle btn-dd btn-dd--primary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              {filter}
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" role='button' onClick={() => setFilter("Year")}>Year</a>
              </li>
              <li>
                <a className="dropdown-item" role='button' onClick={() => setFilter("Month")}>Month</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="toggle-line-chart" className="toggle-line-chart product-grid">
        <div className="line-wrapper line-wrapper-product">
          <ul className="list-inline mb-0 legends">
            <li>
              <div className="pair">
                <div className="pair__color pair__color--primary" />
                <div className="pair__text">
                  Deployed <b className="fw700">{data?.filter(ele => ele.contract_address && ele.project_status !== "verified").length * fixedVal}</b>
                </div>
              </div>
            </li>
            <li>
              <div className="pair">
                <div className="pair__color pair__color--red" />
                <div className="pair__text">
                  Inprogress <b className="fw700">{data?.filter(ele => ele.project_status === "in_progress").length * fixedVal}</b>
                </div>
              </div>
            </li>
            <li>
              <div className="pair">
                <div className="pair__color pair__color--blue" />
                <div className="pair__text">
                  Verified <b className="fw700">{data?.filter(ele => ele.project_status === "verified").length * fixedVal}</b>
                </div>
              </div>
            </li>
          </ul>
          {
            data?.length === 0 &&
            <div class="chart-container illustrationDash">
              {/* <img src="../assets/images/icons/no-stats.svg" /> */}
              <img src="../assets/images/new/meditating.svg" height="80%" />
            </div>
          }
          {
            data?.length > 0 &&
            <div className="chart-container">
              <div style={{ height: '325px' }} >
                <Line options={options} data={chartData} />
              </div>
            </div>
          }
        </div>
        <div className="line-wrapper line-wrapper-service">
          <ul className="list-inline mb-0 legends">
            <li>
              <div className="pair">
                <div className="pair__color pair__color--primary" />
                <div className="pair__text">
                  Responded <b className="fw700">{data?.filter(ele => ele.project_type === "service" && ele.project_status !== "in_progress").length * fixedVal}</b>
                </div>
              </div>
            </li>
            <li>
              <div className="pair">
                <div className="pair__color pair__color--pink" />
                <div className="pair__text">
                  Waiting <b className="fw700">{data?.filter(ele => ele.project_type === "service" && ele.project_status === "in_progress").length * fixedVal}</b>
                </div>
              </div>
            </li>
          </ul>
          <div className="chart-container">
            {
              data?.length === 0 &&
              <div className="center d-flex blur-overlay">
                <img className="mx-auto" src="/assets/images/icons/dashboard-nothing.svg" />
                <img className="mx-auto" src="/assets/images/icons/dashboard-background-inject.svg" />
              </div>
            }
            {
              data?.length > 0 &&
              <div style={{ height: '325px' }} >
                <Line options={options} data={chartDataService} />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}