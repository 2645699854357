import { ethers } from 'ethers';
import React, { useEffect } from 'react'
import Highlight from 'react-highlight'

export default function ShowCodeModal({ data, reload, read = false, write = false, code }) {

  const onSubmit = async (e, abiData) => {
    e.preventDefault();
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const smart_contract = new ethers.Contract(data?.contract_address, data?.abi, signer);

    let trans;
    if (abiData.inputs.length > 0) {
      const inputs = abiData.inputs.map(ele => {
        return document.getElementById("readSMInput" + ele.name).value
      })
      if (abiData.inputs.length === 1) trans = await smart_contract[abiData.name](inputs[0]);
      if (abiData.inputs.length === 2) trans = await smart_contract[abiData.name](inputs[0], inputs[1]);
      if (abiData.inputs.length === 3) trans = await smart_contract[abiData.name](inputs[0], inputs[1], inputs[2]);
      if (abiData.inputs.length === 4) trans = await smart_contract[abiData.name](inputs[0], inputs[1], inputs[2], inputs[3]);
      if (abiData.inputs.length === 5) trans = await smart_contract[abiData.name](inputs[0], inputs[1], inputs[2], inputs[3], inputs[4]);

    } else {
      trans = await smart_contract[abiData.name]();
    }
    document.getElementById("resultReadSM" + abiData.name).innerHTML = trans;
  }

  const onSubmitWrite = async (e, abiData) => {
    e.preventDefault();
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const smart_contract = new ethers.Contract(data?.contract_address, data?.abi, signer);
    const address = await signer.getAddress();

    let trans;
    if (abiData.inputs.length > 0) {
      const inputs = abiData.inputs.map((ele, index) => {
        console.log("writeSMInput" + ele.name + index);
        return document.getElementById("writeSMInput" + ele.name + index).value;
      })
      console.log(inputs);
      if (abiData.inputs.length === 1) trans = await smart_contract[abiData.name](inputs[0], { from: address });
      if (abiData.inputs.length === 2) trans = await smart_contract[abiData.name](inputs[0], inputs[1], { from: address });
      if (abiData.inputs.length === 3) trans = await smart_contract[abiData.name](inputs[0], inputs[1], inputs[2], { from: address });
      if (abiData.inputs.length === 4) trans = await smart_contract[abiData.name](inputs[0], inputs[1], inputs[2], inputs[3], { from: address });
      if (abiData.inputs.length === 5) trans = await smart_contract[abiData.name](inputs[0], inputs[1], inputs[2], inputs[3], inputs[4], { from: address });
    } else {
      trans = await smart_contract[abiData.name]({ from: address });
    }
    document.getElementById("resultWriteSM" + abiData.name).innerHTML = trans;
  }

  return (
    <>
      <div className="modal fade modal--style1" id="showCodeModal" aria-hidden="true" aria-labelledby="showCodeModalRWLabel" tabIndex={-1} data-bs-animation="fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <a className="redirect-back fg-0 me-3" role='button' data-bs-dismiss="modal"><img src="./assets/images/icons/back-arrow.svg" alt="Back arrow" /></a>
              <div className="fg-1">
                <h2 className="modal-title" id="showCodeModalRWLabel">Show Code</h2>
                <p>
                  Most basic token standard, used to create interchangeable
                  tokens.
                </p>
              </div>
              {read &&
                <a role='button' className="btn rounded-0 btn--white-outlined fg-0 me-3" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#readCodeModal">
                  <span className="fw400">{data?.contract_functions_details?.filter(ele => ele.name = "Upgradable").length > 0 ? "Read As Proxy" : "Read Contract"}</span>
                </a>}
              {write &&
                <a role='button' className="btn rounded-0 btn--white-outlined fg-0 me-3" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#writeCodeModal">
                  <span className="fw400">{data?.contract_functions_details?.filter(ele => ele.name = "Upgradable").length > 0 ? "Write as Proxy" : "Write Contract"}</span>
                </a>}
              <a className="fw600 fz14 hide-code fg-0" role='button' data-bs-dismiss="modal" aria-label="Close">
                <svg width={18} height={12} viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.3307 6C17.3307 6 14.8307 11 8.9974 11C3.16406 11 0.664062 6 0.664062 6C0.664062 6 3.16406 1 8.9974 1C14.8307 1 17.3307 6 17.3307 6Z" stroke="white" strokeLinecap="round" />
                  <circle cx={9} cy={6} r="2.5" stroke="white" strokeLinecap="round" />
                </svg>
                Hide Code
              </a>
            </div>
            <div className="modal-body modal-body--showcode">
              <Highlight className="hljs solidity">
                {code}
              </Highlight>
            </div>
          </div>
        </div>
      </div>

      {
        read &&
        <div className="modal fade modal--style1" id="readCodeModal" aria-hidden="true" aria-labelledby="readCodeModalLabel" tabIndex={-1} data-bs-animation="fade">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <a className="redirect-back fg-0 me-3" role="button" data-bs-dismiss="modal"><img src="./assets/images/icons/back-arrow.svg" alt="Back arrow" /></a>
                <div className="fg-1">
                  <h2 className="modal-title" id="readCodeModalLabel">Read Contract</h2>
                  <p>
                    Most basic token standard, used to create interchangeable
                    tokens.
                  </p>
                </div>
                <a role="button" className="btn rounded-0 btn--white fg-0 me-3" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#readCodeModal">
                  <span className="fw400">Read Contract</span>
                </a>
                <a role="button" className="btn rounded-0 btn--white-outlined fg-0 me-3" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#writeCodeModal">
                  <span className="fw400">Write Contract</span>
                </a>
                <a className="fw600 fz14 hide-code fg-0" role="button" data-bs-dismiss="modal" aria-label="Close">
                  <svg width={18} height={12} viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.3307 6C17.3307 6 14.8307 11 8.9974 11C3.16406 11 0.664062 6 0.664062 6C0.664062 6 3.16406 1 8.9974 1C14.8307 1 17.3307 6 17.3307 6Z" stroke="white" strokeLinecap="round" />
                    <circle cx={9} cy={6} r="2.5" stroke="white" strokeLinecap="round" />
                  </svg>
                  Hide Code
                </a>
              </div>
              <div className="modal-body">
                <h3>Read Contract Information</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vehicula
                  massa in enim luctus. Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu.
                </p>
                <div className="accordion" id="accordion1">
                  {
                    data?.abi?.filter(ele => ele.type === "function" && ele.stateMutability === "view").map((ele, index) => (
                      <div className="accordion-item">
                        <h2 className="fz12 accordion-header">
                          <span>{index + 1}. {ele.name}</span>
                          <div className="link-group">
                            <a href="#" className="btn rounded-0 p-0 btn--white-outlined">
                              <img src="./assets/images/icons/file-attachment-icon.svg" alt="Link" />
                            </a>
                            <button className="accordion-button btn rounded-0 p-0 btn--white" type="button" data-bs-toggle="collapse" data-bs-target={"#a1-collapseOne" + index} aria-expanded="true" aria-controls="collapseOne">
                              <img src="./assets/images/icons/arrow-bottom.svg" alt="Accordion arrow" />
                            </button>
                          </div>
                        </h2>
                        <div id={"a1-collapseOne" + index} className="accordion-collapse collapse" data-bs-parent="#accordion1">
                          <div className="accordion-body">
                            <form id={'readSMForm' + ele.name} onSubmit={(e) => onSubmit(e, ele)} className="accordion-form">
                              {
                                ele.inputs.map((obj, index1) => (
                                  <div className="w-100 position-relative form-group">
                                    <label htmlFor className="h-100 fz12 fw500 label">{index1 + 1}. {obj.name}</label>
                                    <input id={"readSMInput" + obj.name} className="h-100 rounded-0 border-0 bg-transparent fz12 form-control" type="text" placeholder={obj.name} />
                                    <a href="#" className="h-100 copy-clipboard ts03">
                                      <img src="./assets/images/icons/copy-clipboard.svg" alt="" />
                                    </a>
                                  </div>
                                ))
                              }
                              {/* <div className="w-100 position-relative form-group">
                                <label htmlFor className="h-100 fz12 fw500 label">2. Spender (Address)</label>
                                <input className="h-100 rounded-0 border-0 bg-transparent fz12 form-control" type="text" placeholder="Spender (Address)" />
                                <a href="#" className="h-100 copy-clipboard ts03">
                                  <img src="./assets/images/icons/copy-clipboard.svg" alt="" />
                                </a>
                              </div> */}
                              <div className="text-end">
                                <button className="btn rounded-0 ms-auto btn--white-outlined">
                                  Query
                                </button>
                              </div>
                              <div id={'resultReadSM' + ele.name} className='text-white result'></div>
                            </form>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {
        write &&
        <div className="modal fade modal--style1" id="writeCodeModal" aria-hidden="true" aria-labelledby="writeCodeModalLabel" tabIndex={-1} data-bs-animation="fade">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <a className="redirect-back fg-0 me-3" role="button" data-bs-dismiss="modal"><img src="./assets/images/icons/back-arrow.svg" alt="Back arrow" /></a>
                <div className="fg-1">
                  <h2 className="modal-title" id="writeModalLabel">Write Contract</h2>
                  <p>
                    Most basic token standard, used to create interchangeable
                    tokens.
                  </p>
                </div>
                <a role="button" className="btn rounded-0 btn--white-outlined fg-0 me-3" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#readCodeModal">
                  <span className="fw400">Read Contract</span>
                </a>
                <a role="button" className="btn rounded-0 btn--white fg-0 me-3" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#writeCodeModal">
                  <span className="fw400">Write Contract</span>
                </a>
                <a className="fw600 fz14 hide-code fg-0" role="button" data-bs-dismiss="modal" aria-label="Close">
                  <svg width={18} height={12} viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.3307 6C17.3307 6 14.8307 11 8.9974 11C3.16406 11 0.664062 6 0.664062 6C0.664062 6 3.16406 1 8.9974 1C14.8307 1 17.3307 6 17.3307 6Z" stroke="white" strokeLinecap="round" />
                    <circle cx={9} cy={6} r="2.5" stroke="white" strokeLinecap="round" />
                  </svg>
                  Hide Code
                </a>
              </div>
              <div className="modal-body">
                <h3>Write Contract Information &gt; Connect to Web3</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vehicula
                  massa in enim luctus. Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu.
                </p>
                <div className="accordion" id="accordion2">
                  {
                    data?.abi?.filter(ele => ele.type === "function" && ele.stateMutability !== "view").map((ele, index) => (
                      <div className="accordion-item">
                        <h2 className="fz12 accordion-header">
                          <span>{index + 1}. {ele.name}</span>
                          <div className="link-group">
                            <a href="#" className="btn rounded-0 p-0 btn--white-outlined">
                              <img src="./assets/images/icons/file-attachment-icon.svg" alt="Link" />
                            </a>
                            <button className="accordion-button btn rounded-0 p-0 btn--white" type="button" data-bs-toggle="collapse" data-bs-target={"#a1-writeOne" + index} aria-expanded="true" aria-controls="writeOne">
                              <img src="./assets/images/icons/arrow-bottom.svg" alt="Accordion arrow" />
                            </button>
                          </div>
                        </h2>
                        <div id={"a1-writeOne" + index} className="accordion-collapse collapse" data-bs-parent="#accordion2">
                          <div className="accordion-body">
                            <form onSubmit={(e) => onSubmitWrite(e, ele)} className="accordion-form">
                              {
                                ele.inputs.map((obj, index1) => (
                                  <div className="w-100 position-relative form-group">
                                    <label htmlFor className="h-100 fz12 fw500 label">1. {obj.name} ({obj.type})</label>
                                    <input id={"writeSMInput" + obj.name + index1} className="h-100 rounded-0 border-0 bg-transparent fz12 form-control" type="text" placeholder={obj.name + ` (${obj.type})`} />
                                    <a href="#" className="h-100 copy-clipboard ts03">
                                      <img src="./assets/images/icons/copy-clipboard.svg" alt="" />
                                    </a>
                                  </div>
                                ))
                              }
                              <div className="text-end">
                                <button className="btn rounded-0 ms-auto btn--white-outlined">
                                  Write
                                </button>
                              </div>
                              <div id={"resultWriteSM" + ele.name} className='text-white result'></div>
                            </form>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
