import abi from "./abi.json";
import nftAbis from "./nftAbis.json";

export const APP_DATA = {
  bscSM: "0x11B6c331ab6eE5ef6fC632dC71CD4f0eC42D99D0",
  polygonSM: "0x9c1f48504103A348c6f09611e0BEd349237DC923",
  goerliSM: "0x6c18d5EBefD9226ED95ec3723Fa1DC2F4E60BB83",
  nft: "0xC260b8c76424347a84a1Cb290eB9aCC68c89bb44",
  abi: abi,
  nftAbi: nftAbis,
  currency: "USD"
}