import React, { useEffect, useState } from 'react'
import { axiosClientPy } from '../utils';
import Loading from '../components/Loading';
import { toast } from 'react-toastify';

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [otp, setOtp] = useState({
    a: '', b: '', c: '', d: '', e: '', f: ''
  })

  const submitFun = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const pData = new FormData();
      pData.append("email", email);
      const res = await axiosClientPy.post("/account/signup", pData);
      setLoading(false);
      setIsOtpSend(true);
    } catch (error) {
      setLoading(false);
      toast.error("account with this email already exists.");
    }
  }

  const submitOTPFun = async (e) => {
    e.preventDefault();
    try {
      const pData = new FormData();
      pData.append("email", email);
      pData.append("email_otp", otp.a + otp.b + otp.c + otp.d + otp.e + otp.f);
      const res = await axiosClientPy.post("/account/confirm-otp", pData);
      window.localStorage.setItem("USER_TOKEN", res.data.token);
      if (res.data?.data?.full_name) {
        window.location.href = "/dashboard";
      } else {
        window.location.href = "/edit-profile";
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ? error?.response?.data?.message : "Internel Server Error!!!")
    }
  }

  const resendOtp = async (e) => {
    try {
      setLoading(true);
      const pData = new FormData();
      pData.append("email", email);
      const res = await axiosClientPy.post("/account/resend-otp", pData);
      toast.success("OTP re-send successfully to your email.")
      setLoading(false);
      setIsOtpSend(true);
    } catch (error) {
      toast.error("Error Sending OTP!!!");
      setLoading(false);
    }
  }

  useEffect(() => {
    if (isOtpSend) {
      const script = document.createElement('script');
      script.src = "/assets/scripts/otp.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, [isOtpSend]);

  const handlePaste = (ev) => {
    const clip = ev.clipboardData.getData('text')
    if (!isNaN(clip) && Number.isInteger(parseInt(clip)) && clip.length >= 6) {
      const tempOTP = {
        a: clip[0],
        b: clip[1],
        c: clip[2],
        d: clip[3],
        e: clip[4],
        f: clip[5],
      }
      setOtp(tempOTP)
      const mInput = document.getElementById('digit-6')
      document.getElementById('digit-1').setAttribute('data-filled', '*')
      document.getElementById('digit-2').setAttribute('data-filled', '*')
      document.getElementById('digit-3').setAttribute('data-filled', '*')
      document.getElementById('digit-4').setAttribute('data-filled', '*')
      document.getElementById('digit-5').setAttribute('data-filled', '*')
      document.getElementById('digit-6').setAttribute('data-filled', '*')
      mInput.select()
    }
  }

  return (
    <>
      <Loading loading={loading} />
      <section className="page-login">
        <div className="loginWrapper">
          <div className="row align-items-center">
            <div className="col-lg-6 text-center">
              <div className="mb-3">
                <img className="logo d-inline-block" src="./assets/images/logo-dark.png" style={{ maxWidth: "400px" }} />
              </div>
              <h2 className="mb-3 fw900">Happy to see you!</h2>
              <p className="mb-5">Please fill out login information to proceed</p>

              {isOtpSend ? (
                <form onSubmit={submitOTPFun} className="digit-group" data-group-name="digits" data-autosubmit="false" autoComplete="off">
                  <div className="mb-3 digit-group-inner">
                    <input type="text" onChange={(e) => { setOtp((prev) => ({ ...prev, a: e.target.value })) }} id="digit-1" name="digit-1" data-next="digit-2" data-filled="" value={otp.a} onPaste={handlePaste}/>
                    <input type="text" onChange={(e) => { setOtp((prev) => ({ ...prev, b: e.target.value })) }} id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1" data-filled="" value={otp.b} onPaste={handlePaste}/>
                    <input type="text" onChange={(e) => { setOtp((prev) => ({ ...prev, c: e.target.value })) }} id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2" data-filled="" value={otp.c} onPaste={handlePaste}/>
                    <input type="text" onChange={(e) => { setOtp((prev) => ({ ...prev, d: e.target.value })) }} id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3" data-filled="" value={otp.d} onPaste={handlePaste}/>
                    <input type="text" onChange={(e) => { setOtp((prev) => ({ ...prev, e: e.target.value })) }} id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4" data-filled="" value={otp.e} onPaste={handlePaste}/>
                    <input type="text" onChange={(e) => { setOtp((prev) => ({ ...prev, f: e.target.value })) }} id="digit-6" name="digit-6" data-next="digit-7" data-previous="digit-5" data-filled="" value={otp.f} onPaste={handlePaste}/>
                  </div>

                  <div className="textInputWrapper mb-4 text-end text-center text-lg-end">
                    <button type='button' onClick={resendOtp} className="resendOtpLink">Resend OTP</button>
                  </div>

                  <button className="loginBtn" type="submit">Let me in!</button>
                </form>
              ) : (
                <form onSubmit={submitFun}>
                  <div className="textInputWrapper mb-5">
                    <img className="icon-user" src="./assets/images/icons/user-dark.svg" alt="user icon" />
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} name="useroremail" placeholder="Enter username or email address" />
                  </div>

                  <button className="loginBtn" type="submit">Next</button>
                </form>
              )}

            </div>
            <div className="col-lg-6 text-center">
              <img className="loginAnim d-inline-block" src="./assets/images/login.svg" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
