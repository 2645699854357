import React, { useEffect, useState } from 'react'
import LeftSidebar from '../components/LeftSidebar'
import TopRightProfile from '../components/TopRightProfile';
import PaymentOptionsModal from '../components/CreateProject/Modals/PaymentOptionsModal';
import useFetch from '../utils/hooks/useFetch';
import Loading from '../components/Loading';
import { APP_DATA, axiosClientPy } from '../utils';
import useRazorpay from 'react-razorpay';
import { toast } from 'react-toastify';

const mScript = `
  $(".owl-carousel").owlCarousel({
    loop: false,
    margin: 20,
    responsiveClass: true,
    nav: true,
    dots: false,
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1850: {
        items: 4,
      },
      2200: {
        items: 5,
      },
    },
  });
`;

export default function Tutorials() {
  const Razorpay = useRazorpay();
  const [couponCode, setCouponCode] = useState("");
  const { data, loading } = useFetch("/courses/courses");
  const [selected, setSelected] = useState(null);
  const headers = {
    Authorization: "Token " + window.localStorage.getItem("USER_TOKEN")
  }

  useEffect(() => {
    if (data && !document.getElementById("tutorialScript")) {
      const script = document.createElement("script");
      script.id = "tutorialScript";
      script.innerHTML = mScript;
      document.body.appendChild(script);
    }
  }, [data]);

  const web2Fun = async () => {
    let orderInfo = {};

    try {
      const apiRes = await axiosClientPy.post("/courses/init-enrollment", { course_id: selected?.uuid }, { headers: headers });
      orderInfo = apiRes?.data?.data;
      console.log(orderInfo);
      const options = {
        key: orderInfo.mrchant_key,
        amount: orderInfo.paise_amount,
        currency: APP_DATA.currency,
        name: "BlockBrew",
        description: "Test Transaction",
        image: window.location.host + "/assets/images/logo-white.png",
        order_id: orderInfo.razorpay_order_id,
        handler: function (response) {
          axiosClientPy.post("/courses/course-callback", response, { headers }).than((paymentCallbackResponse) => {
            window.location.reload();
          }).catch(err => {
            toast.error("Payment Failed!!!");
          })
        },
        prefill: {
          name: "Aditya Sharma",
          email: "aditya242401@gmail.com",
          contact: "6392919732",
        },
        theme: {
          color: "#000000",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        toast.error(response.error.description);
      });

      rzp1.open();
    } catch (error) {
      console.log(error, "Aditya");
    }
  }

  const enrollNow = () => {
    const mData = {
      course_id: selected?.uuid,
      mlm_coupon: couponCode
    }
    axiosClientPy.post("/courses/enroll-course", mData, { headers }).than((resp) => {
      toast.success("Course Purchased successfully.")
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }).catch(err => {
      const msg = err?.response?.data?.message || "An error occurred while enrolling course !!!"
      toast.error(msg);
    })
  }

  return (
    <>
      <Loading loading={loading} />
      <div className="mob-menu-btn"><span /><span /><span /></div>
      <section className='page-wrapper'>
        <LeftSidebar active={"tutorials"} />

        <section className="pe-0 main-content">
          <div className="pt-0 main-content-inner">
            <TopRightProfile />
            {/*----- Completion stats row -----*/}
            <div className="d-none d-lg-flex primary-box completion-row completion-row--back">
              <div className="completion-row__left">
                <a href="/dashboard" className="redirect-back">
                  <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
                </a>
                <div className>
                  <h1 className="fw600 text-white">Courses</h1>
                  <p className="m-0 grayText">
                    Watch &amp; learn from categorised courses
                  </p>
                  <div className="mb-0">
                    <span className="fw700">Last Updated: </span>
                    <span className="ms-1"> 01-June-2022 - 03hr:55min (EST)</span>
                  </div>
                </div>
              </div>
            </div>
            {/* ============================== */}
            <div className="tuts">
              {data?.map((element, index) => (
                <React.Fragment key={index}>
                  <section className="tuts__course course-01">
                    <div className="top-row">
                      <button className="btn bg-transparent p-0 btn-title" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseCourse" + index} aria-expanded="false" aria-controls={"#collapseCourse" + index}>
                        <h2 className="fw600 m-0">{element?.course_name}</h2>
                        <span>
                          <img className="ts03" src="./../assets/images/icons/icon-dd-arrow.svg" alt="" />
                        </span>
                      </button>
                      <div className="ms-auto right">
                        {
                          !element.is_enrolled && (
                            <button onClick={() => setSelected(element)} type="button" data-bs-target="#paymentOptionsModal" data-bs-toggle="modal" className="btn rounded-0 border-0 primary-box btn--primary pay-now">
                              Proceed to Pay
                            </button>
                          )
                        }
                        <div className="dropdown">
                          <a className={`btn rounded-0 border-0 btn--primary resources ${element.is_enrolled ? "" : "locked"}`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="../assets/images/icons/lock-dark.svg" alt="" />
                            <div>
                              Resources
                              <span />
                            </div>
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a className="dropdown-item">Assignments</a>
                            </li>
                            <li>
                              <a className="dropdown-item">Videos</a>
                            </li>
                            <li>
                              <a className="dropdown-item">Other resources</a>
                            </li>
                          </ul>
                        </div>
                        {/* Toggle "locked" class */}
                        <a href={"/exams/" + element.uuid} className={`btn rounded-0 border-0 p-0 btn--primary-outlined ${element.is_enrolled ? "" : "locked"}`} role="button">
                          <img src="../assets/images/icons/lock-dark.svg" alt="" />
                          <div>Exams</div>
                        </a>
                      </div>
                    </div>
                    <div className="w-100 collapse" id={"collapseCourse" + index}>
                      <div className="desc">
                        <p className="m-0 fz14">
                          {element.description}
                        </p>
                      </div>
                    </div>
                    <div className="tuts__course__videos">
                      <div className="owl-carousel owl-theme owl-theme-c01">
                        {
                          element?.course_lectures?.map((item, index2) => (
                            <div className="item" key={index2}>
                              <div className={`item--inner ${index2 === 0 ? "" : element?.is_enrolled ? "" : "locked"}`}>
                                <span className="locker">
                                  <img src="../assets/images/icons/icon-lock.svg" alt="" />
                                </span>
                                <div className="content br-25">
                                  <div className="position-relative img-wrap">
                                    {/* <img
                                      src="https://img-c.udemycdn.com/course/480x270/2034156_1187.jpg"
                                      alt=""
                                    /> */}
                                    {
                                      item.video_url.includes('youtube') ?
                                        <iframe width={560} height={315} src={item?.video_url} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                                        : <video src={item?.video_url} />
                                    }
                                    {/* <div className="fw500 position-absolute text-white lh-1 fz12 duration">
                                      3:05:40
                                    </div> */}
                                  </div>
                                  <div className="video-info">
                                    <div className="top">
                                      <div className="fz14 fw600 numCover">{index2 + 1 < 10 ? "0" + (index2 + 1) : index2 + 1}</div>
                                      <h3 className="mb-0 fz16 fw600" style={{ fontSize: 'clamp(14px, 0.5vw + 10px, 16px)' }}>{item?.course_name}</h3>
                                    </div>
                                    <div className="fz12 fw500 mt-1 grayText bottom">
                                      <div className="views">30.47k views</div>
                                      <div className="timestamp">20 days ago</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                      <div className="ms-auto right d-flex justify-content-end mt-3">
                        <a href={"/tutorials/" + element.uuid} className="btn rounded-0 border-0 primary-box btn--primary pay-now">
                          View All
                        </a>
                      </div>

                    </div>
                  </section>
                  <hr className="s-separator" />
                </React.Fragment>
              ))}
            </div>
          </div>
        </section>
      </section>

      <PaymentOptionsModal web2Fun={web2Fun} course={true} redeem={enrollNow} input={{ couponCode, setCouponCode }} />
    </>
  )
}